import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {KeycloakAuthGuard, KeycloakService} from 'keycloak-angular';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {AuthorizationRestService} from '../core/services/authorization-rest.service';
import {Config} from '../config/config';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class AuthGuardService extends KeycloakAuthGuard implements CanActivateChild {

  static profileLoadedInternal = new BehaviorSubject<any>({});
  public readonly profileLoaded$ = AuthGuardService.profileLoadedInternal.asObservable();
  static profileLoaded = false;
  profileUrl = Config.getApiServer() + '/profile';
  private static userInfo: any = {};

  private permissions: BehaviorSubject<string[]> = new BehaviorSubject([]);

  public readonly permissions$: Observable<string[]> = this.permissions.asObservable();


  constructor(protected router: Router, protected keycloakAngular: KeycloakService,
              protected http: HttpClient,
              protected authorizationRestService: AuthorizationRestService) {
    super(router, keycloakAngular);
  }

  isAuthenticated() {
    return this.keycloakAngular.getKeycloakInstance().authenticated;
  }

  public getUserName() {
    return this.isAuthenticated() ? this.keycloakAngular.getKeycloakInstance().profile.username  : null;
  }

  public doLogout(redirect?: string): Promise<void> {
    AuthGuardService.setUserInfo(null);
    this.clearPermissions();
    this.keycloakAngular.clearToken();
    return this.keycloakAngular.logout(redirect ? redirect : Config.getFrontEndServer());
  }

  public doLogin(redirectUri?: string) {
    return this.keycloakAngular.login({
      redirectUri: Config.getFrontEndServer() + (redirectUri ? redirectUri : '/')
    });
  }

  getToken(): Promise<string> {
    return this.keycloakAngular.getToken();
  }

  updateHeader(headers: HttpHeaders) {
    return this.keycloakAngular.addTokenToHeader(headers);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.isAccessAllowed(route, state);
  }

  isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!this.authenticated) {
        this.doLogin(state.url);
        return;
      }

      this.loadProfileIfNeeded();
      //Obtain the permissions from sessionStorage
      const permissionsFromLocal = this.getPermissions() != null;
      if (permissionsFromLocal) {
        // We have permissions in local
        this.permissions.next(this.getPermissions());
        const hasPermission = this.checkPermission(route.data.resourceName) || this.checkPermission(route.data.resourceName + "_get") || this.checkPermission(route.data.permission);
        if (!hasPermission) {
          this.goToForbidden(route, state);
        }
        resolve(hasPermission);
      } else {
        // We need to get them from backend
        this.authorizationRestService.getPermissions().subscribe(res => {
          this.setPermissions(res);
          const hasPermission = this.checkPermission(route.data.resourceName) || this.checkPermission(route.data.resourceName + "_get") || this.checkPermission(route.data.permission);
          if (!hasPermission) {
            this.goToForbidden(route, state);
          }
          resolve(hasPermission);
        }, err => {
          reject(err != null && err.message != null ? err.message : err);
        });
      }
    });
  }

  public static getUserInfo() {
    return AuthGuardService.userInfo;
  }

  private loadProfileIfNeeded() {
    if (!AuthGuardService.profileLoaded) {
      this.http.get(this.profileUrl)
      .subscribe(info => {
        AuthGuardService.setUserInfo(info);
      }, err => {
        console.error('Failed to fetch user info:', err);
      });
    }
  }

  public static setUserInfo(info: any) {
    AuthGuardService.userInfo = info;
    AuthGuardService.profileLoaded = true;
    AuthGuardService.profileLoadedInternal.next(info);
  }

  private setPermissions(perms: string[]) {
    sessionStorage.setItem('_permissions_', JSON.stringify(perms));
    sessionStorage.setItem('_permissions_time_', '' + Date.now());
    sessionStorage.setItem('_permissions_user_', '' + this.getUserName());
    this.permissions.next(perms != null ? perms : []);
  }

  private clearPermissions() {
    sessionStorage.removeItem('_permissions_');
    sessionStorage.removeItem('_permissions_time_');
    sessionStorage.removeItem('_permissions_user_');
  }

  private getPermissions(): string[] {
    const item = sessionStorage.getItem("_permissions_");
    if (item != null) {
      try {
        const items = JSON.parse(item);
        // Let's check if they are not ooold
        const time = sessionStorage.getItem("_permissions_time_");
        if (time == null || Date.now() > parseInt(time) + (10 * 60 * 1000)) {
          //If there's no time key or they are longer than 10 min, we need to refresh them
          return null;
        }
        // Let's check if they are for the same user
        const username = sessionStorage.getItem("_permissions_user_");
        if (username == null || this.getUserName() == null /*|| username != this.getUserName()*/) {
          return null;
        }
        return items;
      } catch (e) {
        console.log("Can't parse permissions");
      }
    }
    return null;
  }

  private checkPermission(perm: string) {
    if (perm != null) {
      if (perm.indexOf(',') !== -1) {
        let hasPermission = false;
        const permissions = perm.trim().split(',').filter(s => s.trim().length > 0);
        for (const checkPermission of permissions) {
          hasPermission = this.permissions.getValue().findIndex(x => x.toUpperCase() === checkPermission.toUpperCase()) !== -1;
          if (hasPermission) {
            break;
          }
        }
        return hasPermission;
      } else {
        const permissionFound = this.permissions.getValue().findIndex(x => x.toUpperCase() === perm.toUpperCase());
        return permissionFound !== -1;
      }
    }
    return false;
  }

  private goToForbidden(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.router.navigate(['unauthorized'], {
      queryParams: {
        returnUrl: state.url,
        error: 'Access not allowed for path /' + route.url[0].path,
        errorCode: '403'
      }
    });
  }
}
