import {Component} from '@angular/core';
import {AuthGuardService} from '../signin/auth-guard.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  constructor(private authService: AuthGuardService) {
  }

  get username() {
    return this.authService.getUserName();
  }

}
