import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class Company extends DefaultEntity {
  name: string;
  description: string;
}

export interface CompanyPageable extends Pageable {
  content: Company[];
}

