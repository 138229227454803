import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {ChecklistItem, ChecklistItemPageable} from '../../entities/checklistItem';
import {ContentType} from './rest-base';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class ChecklistItemsRestService extends AuthorizedRestBaseService {

  API_CHECKLIST_ITEMS = '/checklistitems';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getChecklistItems(pageNumber: number, pageSize: number, name?: string): Observable<ChecklistItemPageable> {

    let link = this.buildPageLink(this.API_CHECKLIST_ITEMS, pageNumber, pageSize);

    if (name) {
      link += (link.indexOf('?') > 0 ? '&' : '?') + 'name=' + name;
    }
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<ChecklistItemPageable>(link, {headers: opts}).pipe(map(res => {
        Object.assign(new ChecklistItemPageable(), res as ChecklistItemPageable);
        res.content = res.content.map(item => Object.assign(new ChecklistItem(), item as ChecklistItem));
        return res;
      }))),
        catchError(ChecklistItemsRestService.handleError));
  }

  @CacheRemover()
  deleteById(checklistItemId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEMS + '/' + checklistItemId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
        catchError(ChecklistItemsRestService.handleError));
  }

  @Cacheable()
  getById(checklistItemId: number): Observable<ChecklistItem> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEMS + '/' + checklistItemId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<ChecklistItem>(link, {headers: opts})),
        catchError(ChecklistItemsRestService.handleError));
  }

  @CacheRemover()
  save(checklistItem: ChecklistItem): Observable<ChecklistItem> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEMS);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<ChecklistItem>(link, checklistItem, {headers: opts})),
        catchError(ChecklistItemsRestService.handleError));
  }

  @CacheRemover()
  updateById(checklistItemId: number, item: ChecklistItem): Observable<Object> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEMS + '/' + checklistItemId);
    return this.buildHeaders(ContentType.MERGE)
      .pipe(concatMap(opts => this.http.patch<ChecklistItem>(link, item, {headers: opts})),
        catchError(ChecklistItemsRestService.handleError));
  }


}
