import {environment} from '../../environments/environment';

export class Config {
  public static getFrontEndServer(): string {
    return environment.frontEnd.server;
  }


  public static isProduction(): boolean {
    return environment.production;
  }

  public static getApiServer(): string {
    return environment.api.server;
  }

  public static getJiraApi(): string {
    return environment.jira.api;
  }

  public static getJiraWww(): string {
    return environment.jira.www;
  }
}
