import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {LogService} from '../core/services/log.service';
import {MessageManager} from '../core/services/message-manager.service';


@Injectable()
export class AppErrorHandler implements ErrorHandler {

  constructor(private injector: Injector) {

  }


  handleError(error: any): void {
    const router = this.injector.get(Router);
    const messageManager = this.injector.get(MessageManager);
    const logger = this.injector.get(LogService);

    if (error instanceof HttpErrorResponse) {
      // server or connection error happened
      if (!navigator.onLine) {
        logger.error('Browser offline?: ', [error]);
        messageManager.addError('Application is not online!',
          'It seems Internet connection is not working or ' +
          'application server is not available ');
      } else {
        // http errors, add checking for required error codes, and forward
        // to proper page
        logger.error('Unsupported error response: ', [error]);
        if (error != null && error.status == 403) {
          router.navigate(['/unauthorized'], {queryParams: {error: error}});
        } else {
          // messageManager.addError('Unknown HTTP error, please check logs');
          router.navigate(['/error'], {queryParams: {error: error}});
        }
      }
    } else {
      // some internal error, redirect to error page
      // logger.error('Application error: ', [error]);
      console.log(error);
      if (error != null && (error == 403 || error.errorCode == 403 || error.error == 403 || error.status == 403)) {
        router.navigate(['/unauthorized'], {queryParams: {error: error}});
      } else {
        router.navigate(['/error'], {queryParams: {error: error}});
      }
    }

  }

}
