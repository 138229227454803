import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TimelineDto} from '../../entities/timelineDto';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {AuthGuardService} from "../../signin/auth-guard.service";
import {catchError, concatMap} from "rxjs/operators";

@Injectable()
export class TimelineRestService extends AuthorizedRestBaseService {

  API_TIMELINE = '/timeline';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  getBySpecKey(specKey: string): Observable<TimelineDto[]> {
    let link = this.buildPageLink(this.API_TIMELINE + '?ticket=' + specKey);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<TimelineDto[]>(link, {headers: opts})),
      catchError(TimelineRestService.handleError));

  }

  getByDocId(docId: string): Observable<TimelineDto[]> {
    let link = this.buildPageLink(this.API_TIMELINE + '?docId=' + docId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<TimelineDto[]>(link, {headers: opts})),
      catchError(TimelineRestService.handleError));

  }

}
