import {QRTInstance, QRTInstancePageable} from '../../entities/qrtinstance';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class QRTInstanceRestService extends AuthorizedRestBaseService {

  API_INSTANCE = '/qrtinstances';

  constructor(
    protected keycloakService: AuthGuardService,
    protected http: HttpClient
  ) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<QRTInstancePageable> {

    const link = this.buildPageLink(this.API_INSTANCE, pageNumber, pageSize);
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.get<QRTInstancePageable>(link, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));

  }

  @CacheRemover()
  deleteById(instanceId: number) {
    const link = this.buildPageLink(this.API_INSTANCE + '/' + instanceId);
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.delete(link, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));

  }

  @Cacheable()
  getById(instanceId: number): Observable<QRTInstance> {
    const link = this.buildPageLink(this.API_INSTANCE + '/' + instanceId);
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.get<QRTInstance>(link, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));
  }

  @CacheRemover()
  save(qrtInstance: QRTInstance): Observable<QRTInstance> {
    const link = this.buildPageLink(this.API_INSTANCE);
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.post<QRTInstance>(link, qrtInstance, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));

  }

  @CacheRemover()
  updateById(instanceId: number, qrtInstance: QRTInstance): Observable<QRTInstance> {
    const link = this.buildPageLink(this.API_INSTANCE + '/' + instanceId);

    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => {
        return this.http.patch<QRTInstance>(link, qrtInstance, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));

  }

  isInstanceNameAvailable(instanceName: string): Observable<boolean> {
    const link = this.buildPageLink(this.API_INSTANCE + '/isInstanceNameAvailable/' + instanceName);
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.get<boolean>(link, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));
  }

  getInstanceStatus(instanceId: number): Observable<string> {
    const link = this.buildPageLink(this.API_INSTANCE + '/' + instanceId + '/status');
    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.get<string>(link, {headers: opts});
      }),
      catchError(QRTInstanceRestService.handleError));
  }
}
