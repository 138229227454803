import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';


@Component({
  templateUrl: './unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {

  error: any;
  errorCode = 403;

  // Create constructor to get service instance
  constructor(
    private route: ActivatedRoute,
    private location: Location) {
  }

  ngOnInit(): void {
    this.error = 'Forbidden';
    this.route.queryParams.subscribe(params => {
      this.error = params['error'];
    });

  }

  back() {
    this.location.back();
  }
}
