import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class User extends DefaultEntity {
  name: string;
  lastName: string;
  email: string;
  jiraLogin: string;
  avatarUrl: string;
  status: number;
  available: number;
  leader: number;
  reviewer: number;
  author: number;
}

export interface UserPageable extends Pageable {
  content: User[];
}

