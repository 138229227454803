import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {ChecklistListSection} from '../../entities/checklistListSection';
import {catchError, concatMap} from 'rxjs/operators';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {AuthGuardService} from "../../signin/auth-guard.service";

@Injectable()
export class ChecklistListSectionsRestService extends AuthorizedRestBaseService {

  API_CHECKLIST_LIST_SECTIONS = '/checklistlistsection';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  deleteById(checklistItemId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_LIST_SECTIONS + '/' + checklistItemId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(ChecklistListSectionsRestService.handleError));
  }

  save(checklistListSection: ChecklistListSection): Observable<ChecklistListSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_LIST_SECTIONS);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<ChecklistListSection>(link, checklistListSection, {headers: opts})),
      catchError(ChecklistListSectionsRestService.handleError));
  }

  updateById(checklistItemId: number, item: ChecklistListSection): Observable<Object> {
    const link = this.buildPageLink(this.API_CHECKLIST_LIST_SECTIONS + '/' + checklistItemId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<ChecklistListSection>(link, item, {headers: opts})),
      catchError(ChecklistListSectionsRestService.handleError));
  }

  updateSectionOrder(checklistlistSectionId: number, order: number): Observable<void> {
    const link = this.buildPageLink(this.API_CHECKLIST_LIST_SECTIONS + '/' + checklistlistSectionId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<void>(link, {order: order}, {headers: opts})),
      catchError(ChecklistListSectionsRestService.handleError));
  }

  getByChecklistId(checklistId: number): Observable<ChecklistListSection[]> {
    const link = this.buildPageLink(this.API_CHECKLIST_LIST_SECTIONS + '?checklistId=' + checklistId + '&sort=sectionOrder');
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ChecklistListSection[]>(link, {headers: opts})),
      catchError(ChecklistListSectionsRestService.handleError));

  }

}
