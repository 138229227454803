import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {User, UserPageable} from '../../entities/user';
import {LogService} from './log.service';
import {catchError, concatMap} from 'rxjs/operators';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';


@Injectable()
export class UsersRestService extends AuthorizedRestBaseService {

  API_USERS = '/users';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<UserPageable> {
    return this.getUsersFromLink('', pageNumber, pageSize);
  }

  @Cacheable()
  getById(userId: number): Observable<User> {
    const link = this.buildPageLink(this.API_USERS + '/' + userId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<User>(link, {headers: opts})),
      catchError(UsersRestService.handleError));
  }

  @CacheRemover()
  updateById(userId: number, user: User): Observable<User> {
    const link = this.buildPageLink(this.API_USERS + '/' + userId);

    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<User>(link, user, {headers: opts})),
      catchError(UsersRestService.handleError));
  }

  @Cacheable()
  getAllReviewers(pageNumber: number, pageSize: number): Observable<UserPageable> {
    return this.getUsersFromLink('/reviewers', pageNumber, pageSize);
  }

  @Cacheable()
  getAvailableReviewers(pageNumber: number, pageSize: number): Observable<UserPageable> {
    return this.getUsersFromLink('/availablereviewers', pageNumber, pageSize);
  }

  @Cacheable()
  getAllLeaders(pageNumber: number, pageSize: number): Observable<UserPageable> {
    return this.getUsersFromLink('/leaders', pageNumber, pageSize);
  }

  @Cacheable()
  getAvailableLeaders(pageNumber: number, pageSize: number): Observable<UserPageable> {
    return this.getUsersFromLink('/availableleaders', pageNumber, pageSize);
  }


  private getUsersFromLink(link: string, pageNumber: number, pageSize: number): Observable<UserPageable> {
    const fullLink = this.buildPageLink(this.API_USERS + link, pageNumber, pageSize);

    return this.buildHeaders()
    .pipe(concatMap(opts => {
        return this.http.get<UserPageable>(fullLink, {headers: opts});
      }),
      catchError(UsersRestService.handleError));
  }

}
