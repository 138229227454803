import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {LogEntry, LogEntryPageable} from '../../entities/logEntry';
import {LogService} from './log.service';
import {Observable} from 'rxjs';
import {Config} from '../../config/config';


@Injectable()
export class LogRestService {

  constructor(private logger: LogService, private http: HttpClient) {
  }

  getLogs(pageNumber: number, pageSize: number): Observable<LogEntryPageable> {

    const link = this.buildLink('/logs?pageNumber=' + pageNumber + '&pageSize=' + pageSize);
    const headers: HttpHeaders = this.buildHeaders();


    return this.http.get<LogEntryPageable>(link, {
      headers: headers
    });
  }

  getLog(logId: number): Observable<LogEntry> {
    const link = this.buildLink('/logs/' + logId);
    const headers: HttpHeaders = this.buildHeaders();

    return this.http.get<LogEntry>(link, {
      headers: headers
    });
  }

  buildLink(append: string): string {
    let link = Config.getApiServer();

    if (append) {
      link += append;
    }

    return link;
  }

  buildHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return headers;
  }

}
