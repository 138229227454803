import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';


@Component({
  templateUrl: './error.component.html'
})
export class ErrorComponent implements OnInit {

  error: any;
  errorCode:any;

  // Create constructor to get service instance
  constructor(
    private route: ActivatedRoute,
    private location: Location) {
  }

  ngOnInit(): void {
    // this is very bad way to pass data, as this can be used to inject some code
    // on the page. This value should not be displayed to the user
    // we have to find other way to pass it, or extract some
    // useful information from this.

    this.route.queryParams.subscribe(params => {
      this.error = params['error'];
      this.errorCode = params['errorCode'];
    });



  }

  back() {
    this.location.back();
  }
}
