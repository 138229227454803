import {Injectable} from '@angular/core';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Subject} from 'rxjs';

@Injectable()
export class HasPermissionService {
  private userPermissions = [];
  private permissionsChanged = new Subject();
  public userPermissionsChanged$ = this.permissionsChanged.asObservable();

  constructor(private userService: AuthGuardService) {
    this.userService.permissions$.subscribe(permissions => {
      this.userPermissions = permissions;
      this.permissionsChanged.next({});
    });
  }

  hasPermission(val: string | string[]): boolean {
    let permissions = [];
    if (typeof val === 'string') {
      permissions = val.trim().split(',').filter(s => s.trim().length > 0);
    } else {
      permissions = val;
    }
    return this.checkPermission(permissions);
  }

  private checkPermission(permissions: string[]) {
    let hasPermission = false;

    if (this.userPermissions) {
      for (const checkPermission of permissions) {
        hasPermission = this.userPermissions.findIndex(x => x.toUpperCase() === checkPermission.toUpperCase()) !== -1;
        if (hasPermission) {
          break;
        }
      }
    }

    return hasPermission;
  }

}
