import {Env} from '../app/config/env';

export const environment = {
  production: false,
  env: Env.Docker,
  api: {
    server: '/backend'
  },
  frontEnd: {
    server: 'QRT_FRONTEND_URL'
  },
  jira: {
    api: 'https://jira.devfactory.com/rest/api',
    www: 'https://jira.devfactory.com'
  },
  keycloak: {
    'realm': 'QRT',
    // "url": "http://localhost:8081/auth",
    'url': 'QRT_KEYCLOAK_URL',
    'ssl-required': 'external',
    'clientId': 'QRT_KEYCLOAK_CLIENT',
    // "public-client": true,
    'credentials': {
      'secret': 'QRT_KEYCLOAK_TOKEN'
    },
    'use-resource-role-mappings': true,
    'enable-cors': true,
    'confidential-port': 0,
    'policy-enforcer': {},
    "checkLoginIframe" : false
  }
};
