import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class ChecklistGroup extends DefaultEntity {
  key: string;
  name: string;
  description: string;
}

export interface ChecklistGroupPageable extends Pageable {
  content: ChecklistGroup[];
}
