import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {catchError, concatMap, map, share} from 'rxjs/operators';
import {Review, ReviewResult, ReviewStatus} from '../../entities/review';
import {ReviewSection} from '../../entities/reviewSection';
import {FileInfo} from '../../entities/fileInfo';
import {Iteration} from '../../entities/iteration';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {ChecklistItemValue, ChecklistValue} from '../../entities/checklistItemValue';
import {Cacheable} from '../../utils/cacheable.decorator';

@Injectable()
export class ReviewRestService extends AuthorizedRestBaseService {

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  API_REVIEWS = '/reviews';
  API_ITERATIONS = '/iterations';

  getById(reviewId: number): Observable<Review> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<Review>(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  deleteById(reviewId: number) {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  save(review: Review): Observable<Review> {
    const link = this.buildPageLink(this.API_REVIEWS);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<Review>(link, review, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  updateById(reviewId: number, item: Review): Observable<Object> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId);
    return this.buildHeaders(ContentType.MERGE)
      .pipe(concatMap(opts => this.http.patch<Review>(link, item, {headers: opts})));
  }

  getSections(reviewId: number): Observable<ReviewSection[]> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<ReviewSection[]>(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  updateSectionOrder(reviewId: number, reviewSectionId: number, order: number): Observable<void> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections/' + reviewSectionId);
    return this.buildHeaders(ContentType.MERGE)
      .pipe(concatMap(opts => this.http.patch<void>(link, {sectionOrder: order}, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  addSection(reviewId: number, reviewSection: ReviewSection): Observable<ReviewSection> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<ReviewSection>(link, reviewSection, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  addSections(reviewId: number, reviewSections: ReviewSection[]): Observable<ReviewSection[]> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<ReviewSection[]>(link, reviewSections, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }


  public cloneSection(reviewId: number, sectionId: number, title: any) {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections/clone');

    return this.buildHeaders().
    pipe(concatMap(opts => this.http.post<ReviewSection>(link, {
      action: 'COPY',
      entity: {
        id: sectionId,
        title: title
      },
    }, {headers: opts}).pipe(
      map(data => data),
      catchError(ReviewRestService.handleError))));
  }


  deleteSectionById(reviewId: number, reviewSectionId: number): Observable<any> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/sections/' + reviewSectionId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }


  updateAnswer(reviewId: number, checklistItemValueId: number, value: ChecklistValue): Observable<ChecklistItemValue> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/answers/' + checklistItemValueId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.patch<ChecklistItemValue>(link, {value: value}, {headers: opts}).pipe(
      map(data => data),
      catchError(ReviewRestService.handleError),
      share())));
  }


  updateAnswers(reviewId: number, checklistItemValueId: number[], value: ChecklistValue): Observable<ChecklistItemValue[]> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/answers');

    const body: object[] = [];
    for (let i = 0; i < checklistItemValueId.length; i++) {
      body.push({'checklistItemValueId': checklistItemValueId[i], 'value': value});
    }

    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<ChecklistItemValue[]>(link, body, {headers: opts}).pipe(
      map(data => data),
      catchError(ReviewRestService.handleError),
      share())));

  }


  getHtmlFile(iterationId: number, isDiff = false, version: string = null, prevIterationId: number = null, withQBRules: boolean = false) {
    return this.getFile(iterationId, 'html', isDiff, version, prevIterationId, withQBRules);
  }


  @Cacheable()
  getFileInfo(iterationId: number): Observable<FileInfo> {
    const link = this.buildPageLink(this.API_ITERATIONS + '/' + iterationId + '/file/info');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<FileInfo>(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }


  makeStatusTransition(reviewId: number, status: ReviewStatus, result?: ReviewResult): Observable<any> {
    const link = this.buildPageLink(this.API_REVIEWS + '/' + reviewId + '/status');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<any>(link, {status: status, result: result}, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  @Cacheable()
  getPreviousIterations(iterationId: number): Observable<Iteration[]> {
    const link = this.buildPageLink(this.API_ITERATIONS + '/' + iterationId + '/previousIterations');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<Iteration[]>(link, {headers: opts})),
        catchError(ReviewRestService.handleError));
  }

  private getFile(iterationId: number, format: string, isDiff = false, version: string = null, prevIterationId: number = null, withQBRules: boolean = false): Observable<any> {
    const link = this.buildPageLink(this.API_ITERATIONS + '/' + iterationId
      + '/file' + (isDiff ? '/diff' : '')
      + '?format=' + format
      + '&withQBRules=' + withQBRules
      + (version != null ? '&version=' + version : '')
      + (prevIterationId != null ? '&iterationId=' + prevIterationId : '')
    );
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get(link, {headers: opts, responseType: 'text'})),
        catchError(ReviewRestService.handleError));
  }

  public getReviewsOfIteration(iterationId: number): Observable<Review[]> {
    const link = this.buildPageLink(this.API_ITERATIONS + '/' + iterationId + '/reviews');

    return this.buildHeaders().
    pipe(concatMap(opts => this.http.get<Review[]>(link, {headers: opts}).pipe(
      map(data => data),
      catchError(ReviewRestService.handleError))));
  }

}
