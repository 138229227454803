import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorComponent} from './core/components/errors/error.component';
import {SignedInComponent} from './signin/signedin.component';
import {AuthGuardService} from './signin/auth-guard.service';
import {LoginComponent} from './signin/login.component';
import {MainComponent} from './layout/main.component';
import {HomeComponent} from './home/home.component';
import {Config} from './config/config';
import {UnauthorizedComponent} from './core/components/errors/unauthorized/unauthorized.component';


const appMainRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QC Dashboard', resourceName: 'dashboard'}
  },
  {
    path: 'timeline',
    loadChildren: './timeline/timeline.module#TimelineModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'Timeline', resourceName: 'timeline'}
  },
  {
    path: 'logs',
    loadChildren: './logs/logs.module#LogsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Logs', resourceName: 'logs'}
  },
  {
    path: 'checklistitems',
    loadChildren: './checklist-items/checklist-items.module#ChecklistItemsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Checklist Items', resourceName: 'checklists'}
  },
  {
    path: 'checklistsections',
    loadChildren: './checklist-sections/checklist-sections.module#ChecklistSectionsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Checklist Items', resourceName: 'checklists'}
  },
  {
    path: 'checklists',
    loadChildren: './checklists/checklists.module#ChecklistsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Checklist', resourceName: 'checklists'}
  },
  {
    path: 'companies',
    loadChildren: './companies/companies.module#CompaniesModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Companies', resourceName: 'companies'}
  },
  {
    path: 'products',
    loadChildren: './products/products.module#ProductsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Products', resourceName: 'products'}
  },
  {
    path: 'sla-definitions',
    loadChildren: './sla-definitions/sla-definitions.module#SlaDefinitionsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT SLA Definitions', resourceName: 'sla-definitions'}
  },
  {
    path: 'iteration-requests',
    loadChildren: './iteration-requests/iteration-requests.module#IterationRequestsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Iteration Requests', resourceName: 'iteration-requests'}
  },
  {
    path: 'users',
    loadChildren: './users/users.module#UsersModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Users', resourceName: 'users'}
  },
  {
    path: 'document-repository-configurations',
    loadChildren: './document-repo-config/document-repo-config.module#DocumentRepoConfigModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Document Repository Configuration', resourceName: 'doc-repo-config'}
  },
  {
    path: 'qrt-instances',
    loadChildren: './qrt-instances/qrt-instances.module#QrtInstancesModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Instances', resourceName: 'qrt-instances'}
  },
  {
    path: 'reviews',
    loadChildren: './reviews/reviews.module#ReviewsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Instances', resourceName: 'reviews'}
  },
  {
    path: 'results',
    loadChildren: './results/results.module#ResultsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Results', resourceName: 'results'}
  },
  {
    path: 'stats',
    loadChildren: './stats/stats.module#StatsModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService],
    data: {title: 'QRT Stats', resourceName: 'stats'}
  },

  {
    path: 'error', component: ErrorComponent
  },
  {
    path: 'unauthorized', component: UnauthorizedComponent
  },
  {
    path: '', component: HomeComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: '**', component: ErrorComponent,
    data: {error: '404'}
  }
];

const signedInRoute: Routes = [
  {
    path: '',
    component: SignedInComponent,
  },
];

const appRoutes: Routes = [
  {
    path: 'signedin',
    component: SignedInComponent,
    data: {title: 'Public Views'},
    children: signedInRoute
  },
  {
    path: 'files',
    data: {title: 'File Views', resourceName: 'files'},
    loadChildren: './files/files.module#FilesModule',
    canActivate: [AuthGuardService],
    canActivateChild: [AuthGuardService]
  },
  {path: '', component: MainComponent, data: {title: 'Public Views'}, children: appMainRoutes},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {enableTracing: !Config.isProduction()} // <-- debugging purposes only
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
