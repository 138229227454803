import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

/*
 * Replaces a string with another
 * Usage:
 *   value | replace:searchedString:replacementString
 * Example:
 *   {{ "In_progress" | replace:"_":" " }}
 *   formats to: "In progress"
*/
@Pipe({name: 'replace', pure: true})
export class ReplacePipe implements PipeTransform {
  transform(value: string, searchedString: string, replacementString: string): string {
    if (value == null) {
      return '';
    }
    let regexp = new RegExp(searchedString, "g");
    return value.replace(regexp,replacementString);
  }
}
