import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ClickOutsideDirective} from './directives/clickOutside-directive';
import {MomentPipe} from './pipes/moment.pipe';
import {ReviewerListPipe} from './pipes/reviewer-list.pipe';
import {SortEntityByPipe} from './pipes/sort-entity-by.pipe';
import {FormErrorMessageComponent} from './components/form-error-message/form-error-message.component';
import {InPlaceEditComponent} from './components/in-place-edit/in-place-edit.component';
import {MessageModule} from 'primeng/message';
import {CalendarModule, DropdownModule, MenuModule, TreeTableModule} from 'primeng/primeng';
import {FormsModule} from '@angular/forms';
import {ChecklistInfoComponent} from './components/checklist-info/checklist-info.component';
import {UserInfoComponent} from './components/user-info/user-info.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {ReplacePipe} from './pipes/replace.pipe';
import {HasPermissionDirective} from './directives/hasPermission.directive';
import {HasPermissionService} from './services/has-permission.service';

@NgModule({
  imports: [
    CommonModule,
    MessageModule,
    DropdownModule,
    FormsModule,
    CalendarModule,
    TreeTableModule,
    MenuModule,
  ],
  declarations: [
    ClickOutsideDirective,
    MomentPipe,
    ReplacePipe,
    ReviewerListPipe,
    SortEntityByPipe,
    FormErrorMessageComponent,
    InPlaceEditComponent,
    ChecklistInfoComponent,
    UserInfoComponent,
    HasPermissionDirective,
    UserMenuComponent,
  ],
  exports: [
    ClickOutsideDirective,
    MomentPipe,
    ReplacePipe,
    ReviewerListPipe,
    SortEntityByPipe,
    FormErrorMessageComponent,
    InPlaceEditComponent,
    ChecklistInfoComponent,
    UserInfoComponent,
    UserMenuComponent,
    HasPermissionDirective,
  ],
  providers:[
    HasPermissionService,
  ]
})
export class SharedModule { }
