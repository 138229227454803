import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';
import {StatPageable} from '../../entities/stats';
import {RequestSummaryDTOPageable} from '../../entities/requestSummaryDto';

@Injectable()
export class StatsRestService extends AuthorizedRestBaseService {

  API_STATS = '/stats';


  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }


  getReviewerPage(pageNumber: number, pageSize: number, params: any): Observable<StatPageable> {

    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_STATS + '/iterations/reviewer', pageNumber, pageSize, strParams);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<StatPageable>(link, {headers: opts})),
      catchError(StatsRestService.handleError));
  }


  getAuthorPage(pageNumber: number, pageSize: number, params: any): Observable<StatPageable> {

    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_STATS + '/iterations/author', pageNumber, pageSize, strParams);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<StatPageable>(link, {headers: opts})),
        catchError(StatsRestService.handleError));
  }


  getIterationDetailed(pageNumber: number, pageSize: number, params: any): Observable<RequestSummaryDTOPageable> {

    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_STATS + '/iterations/detailed', pageNumber, pageSize, strParams);

    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<RequestSummaryDTOPageable>(link, {headers: opts})),
        catchError(StatsRestService.handleError));
  }

  getIterationDetailedCSV(pageNumber: number, pageSize: number, params: any): Observable<RequestSummaryDTOPageable> {

    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_STATS + '/iterations/detailed/csv', pageNumber, pageSize, strParams);

    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<RequestSummaryDTOPageable>(link, {headers: opts})),
        catchError(StatsRestService.handleError));
  }

}
