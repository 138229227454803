import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {User} from '../../../entities/user';
import {UsersRestService} from '../../../core/services/users-rest.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})

export class UserInfoComponent implements OnChanges {

  constructor(private element: ElementRef, private usersRestService: UsersRestService) {
  }

  /**
   * The user to display
   * @type {User}
   */
  @Input('user') user: User = null;
  /**
   * The user id to display.
   * A request will be made to obtain the user data.
   * @default null
   * @type {number}
   */
  @Input('userId') userId: number = null;

  ngOnInit() {

  }

  // Lifecycle hook. angular.io for more info
  ngOnChanges(changes: SimpleChanges) {
    // set placeholder default value when no input given to pH property
    if (changes['userId']) {
      this.user = null;
      const value = changes['userId'].currentValue;
      if (value != null) {
        this.usersRestService.getById(value).subscribe(data => {
          this.user = data;
        });
      }
    } else if (changes['user']) {
      const value = changes['user'].currentValue;
      this.userId = value != null ? value.id : null;
    }
  }

}
