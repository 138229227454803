import {DefaultEntity} from './defaultEntity';
import {ChecklistItem} from './checklistItem';
import {ChecklistSection} from './checklistSection';
import {Pageable} from './pageble';
import {ChecklistGroup} from './checklistGroup';
import {RelationshipType} from './relationshipType';

export class ChecklistItemSection extends DefaultEntity {
  item: ChecklistItem;
  section: ChecklistSection;
  group: ChecklistGroup;
  parentId: number;
  relationshipType: RelationshipType;
  sectionOrder: number;
  required: boolean;
}

export interface ChecklistItemSectionPageable extends Pageable {
  content: ChecklistItemSection[];
}

export class ItemGroup {
  items: ChecklistItem[];
  groups: ChecklistGroup[];
}
