import {Pipe, PipeTransform} from '@angular/core';
import {Dashboard} from '../../entities/dashboard';
import {User} from '../../entities/user';

/*
 * Displays a slice of a user list
 * Takes an displayCount argument that defaults to 2.
 * Usage:
 *   value | userList:displayCount
 * Example:
 *   {{ dashboard | userList:2 }}
 *   formats to: Lukasz Stelmach, Volkan Erdogan and 2 more
*/
@Pipe({name: 'reviewerList', pure: false})
export class ReviewerListPipe implements PipeTransform {
  transform(value: Dashboard, displayCount: number = 2): string {
    if (value == null || value.reviews == null || value.reviews.length === 0) {
      return 'No reviewers';
    }
    const tempReviewers: object = {};
    value.reviews.map((r) => tempReviewers[r.reviewer.email] = r.reviewer);
    const reviewers: User[]  = [];
    for (const i in tempReviewers) {
      reviewers.push(tempReviewers[i]);
    }

    const displayedUsers: string[] = [];
    for (let i = 0; i < displayCount && i < reviewers.length; i++) {
      displayedUsers.push(reviewers[i].name + (reviewers[i].lastName != null ? ' ' + reviewers[i].lastName : ''));
    }
    let output = displayedUsers.join(', ');
    const remaining = reviewers.length - displayCount;
    if (remaining > 0) {
      if (displayedUsers.length > 0) {
        output = output + ' and ';
      }
      output = output + ' ' + remaining + ' more';
    }
    return output;
  }
}
