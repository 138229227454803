import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class ChecklistItem extends DefaultEntity {
  key: string;
  name: string;
  description: string;
}

export class ChecklistItemPageable implements Pageable {
  content: ChecklistItem[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    sort: { sorted: boolean; unsorted: boolean };
    pageSize: number; pageNumber: number; offset: number; paged: boolean; unpaged: boolean
  };
  size: number;
  sort: { sorted: boolean; unsorted: boolean };
  totalElements: number;
  totalPages: number;
}
