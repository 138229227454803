import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {ContentType} from './rest-base';
import {Observable, throwError} from 'rxjs';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Config} from '../../config/config';
import {SortOrder} from '../../entities/sortOrder';

export class AuthorizedRestBaseService {

  constructor(protected keycloakService: AuthGuardService, protected http: HttpClient) {
  }


  buildLink(append: string): string {
    let link = Config.getApiServer();
    if (append) {
      link += append;
    }
    return link;
  }

  buildPageLink(append: string, pageNumber?: number, pageSize?: number, additionalParams?: string) {
    let link = this.buildLink(append);
    if (pageNumber || pageSize || additionalParams) {
      link += '?';
      if (pageNumber) {
        link += 'pageNumber=' + pageNumber + '&';
      }
      if (pageSize) {
        link += 'pageSize=' + pageSize + '&';
      }
      if (additionalParams) {
        link += additionalParams;
      }
    }
    return link;
  }


  buildHeaders(contentType = ContentType.JSON): Observable<HttpHeaders> {

    const headers = new HttpHeaders({
      'Content-Type': contentType
    });

    return this.keycloakService.updateHeader(headers);
  }


  sortToString(sortOrders?: SortOrder[]): string {
    if (!sortOrders) {
      return null;
    }
    return "sort="+sortOrders.map(s => s.getAsString()).join("&sort=")
  }

  protected static handleError(error: HttpResponse<any> | any) {
    console.error(error.message || error);
    return throwError({
      status: error.status,
      message: (error.error ? error.error.message : error.message)
    });
  }

}
