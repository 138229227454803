// These first 3 lines will be deprecated by the final release
import {Component} from '@angular/core';
import {Subscription} from 'rxjs/Subscription';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {AuthGuardService} from "./auth-guard.service";

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  session: any;
  subscription: Subscription;
  returnUrl: string;


  constructor(private authService: AuthGuardService, private route: ActivatedRoute,
              private router: Router, private location: Location) {
     // this.authService.doLogout();

    // this.subscription = this.authService.getSession().subscribe(session => {
    //   this.session = session;
    //   console.log(this.session);
    //   if (session.authenticated) {
    //     // Navigate to origin page
    //     this.router.navigateByUrl(this.returnUrl);
    //   }
    // });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }


   isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  back() {
    this.location.back();
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.subscription.unsubscribe();
  }

}
