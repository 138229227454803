import {ReplaySubject} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class CacheService {
  constructor(){

  }

  remove(name, key: string){
    CacheStoreBuilder.getCache(name.name).remove(key);
  }
  clear(name) {
    CacheStoreBuilder.getCache(name.name).clear();
  }
}

export class CacheStoreBuilder {
  private static caches: any = {};

  public static getCache(name: string): CacheStore {
    CacheStoreBuilder.caches[name] = CacheStoreBuilder.caches[name] || new CacheStore();
    return CacheStoreBuilder.caches[name];
  }

}

export class CacheStore {
  // By default, the cache values are stored 10 minutes
  public set<T>(key: string, v: ReplaySubject<T>, ttl: number = (10 * 60 * 1000)) {
    this.caches[key] = v;
    this.cachesInfo[key] = {date: Date.now(), ttl: ttl};
  }

  public get<T>(key: string): ReplaySubject<T> | null {
    if (key in this.caches && key in this.cachesInfo) {
      const info = this.cachesInfo[key];
      if (Date.now() < info.date + info.ttl) { // if the ttl hasn't been reached
        return this.caches[key];
      }else{
        console.log('expired');
      }
    }
    return null;
  }

  public remove(key: string) {
    delete this.caches[key];
    delete this.cachesInfo[key];
  }

  public clear() {
    this.caches = {};
    this.cachesInfo = {};
  }

  public keys(): string[] {
    return Object.keys(this.caches);
  }

  private caches: any = {};
  private cachesInfo: any = {};
}
