import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {Product, ProductPageable} from '../../entities/product';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class ProductsRestService extends AuthorizedRestBaseService {

  API_PRODUCT = '/products';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<ProductPageable> {

    const link = this.buildPageLink(this.API_PRODUCT, pageNumber, pageSize);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ProductPageable>(link, {headers: opts})),
      catchError(ProductsRestService.handleError));
  }

  @CacheRemover()
  deleteById(productId: number) {
    const link = this.buildPageLink(this.API_PRODUCT + '/' + productId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(ProductsRestService.handleError));
  }

  @Cacheable()
  getById(productId: number): Observable<Product> {
    const link = this.buildPageLink(this.API_PRODUCT + '/' + productId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<Product>(link, {headers: opts})),
      catchError(ProductsRestService.handleError));
  }

  @CacheRemover()
  save(product: Product): Observable<Product> {
    const link = this.buildPageLink(this.API_PRODUCT);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<Product>(link, product, {headers: opts})),
      catchError(ProductsRestService.handleError));
  }

  @CacheRemover()
  updateById(productId: number, product: Product): Observable<Product> {
    const link = this.buildPageLink(this.API_PRODUCT + '/' + productId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<Product>(link, product, {headers: opts})),
      catchError(ProductsRestService.handleError));
  }
}
