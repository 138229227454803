import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {ChecklistSection, ChecklistSectionPageable} from '../../entities/checklistSection';
import {ContentType} from './rest-base';
import {catchError, concatMap} from 'rxjs/operators';
import {ChecklistItemSection} from '../../entities/checklistItemSection';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {AuthGuardService} from "../../signin/auth-guard.service";
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class ChecklistSectionsRestService extends AuthorizedRestBaseService {

  API_CHECKLIST_SECTIONS = '/checklistsections';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getChecklistSections(pageNumber: number, pageSize: number): Observable<ChecklistSectionPageable> {

    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS, pageNumber, pageSize);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<ChecklistSectionPageable>(link, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  @CacheRemover()
  deleteById(checklistSectionId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS + '/' + checklistSectionId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  getById(checklistSectionId: number): Observable<ChecklistSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS + '/' + checklistSectionId);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.get<ChecklistSection>(link, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  @CacheRemover()
  save(checklistSection: ChecklistSection): Observable<ChecklistSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS);
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<ChecklistSection>(link, checklistSection, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  @CacheRemover()
  updateById(checklistSectionId: number, section: ChecklistSection): Observable<ChecklistSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS + '/' + checklistSectionId);
    return this.buildHeaders(ContentType.MERGE)
      .pipe(concatMap(opts => this.http.patch<ChecklistSection>(link, section, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  @CacheRemover()
  updateSectionOrder(checklistSectionId: number, checklistItemSectionId: number[], order: number[]): Observable<void> {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS + '/' + checklistSectionId + '/ordering');
    const obj: object[] = [];
    for (let i = 0; i < checklistItemSectionId.length; i++) {
      obj.push({
        'checklistItemSectionId': checklistItemSectionId[i],
        'order': order[i]
      });
    }
    return this.buildHeaders(ContentType.MERGE)
      .pipe(concatMap(opts => this.http.patch<void>(link, obj, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

  @CacheRemover()
  addItem(checklistItemId: number, checklistItemSection: ChecklistItemSection): Observable<ChecklistSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_SECTIONS + '/' + checklistItemId + '/checklistitemsection');
    return this.buildHeaders()
      .pipe(concatMap(opts => this.http.post<ChecklistSection>(link, checklistItemSection, {headers: opts})),
        catchError(ChecklistSectionsRestService.handleError));
  }

}
