import {Review} from './review';
import {User} from './user';
import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';
import {ChecklistItemReply} from './checklistItemReply';

export class ChecklistItemComment extends DefaultEntity {
  review: Review;
  comment: string;
  highlightText: string;
  author: User;
  relatedValue: any;
  solved: boolean;
  position: Position;
  originalPosition: Position;

  replies: ChecklistItemReply[] = [];

  /**
   * Indicates the user is intending to initiate the comment-thread
   * but it still needs to save it
   * @returns {boolean} true if the comment hasn't been saved, false otherwise
   */
  public isIntent() {
    return this.id == null;
  }
}

export class Position {
  x = 0;
  y = 0;
  zIndex = 1000;
}


export interface ChecklistItemCommentPageable extends Pageable {
  content: ChecklistItemComment[];
}
