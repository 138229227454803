import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

/*
 * Displays a date in relative time
 * e.g. 24 minutes ago, 3 min ago, 2 days ago, etc.
 * Takes a type argument that defaults to 'calendar'.
 * Usage:
 *   value | moment:type
 * Example:
 *   {{ aDate | moment:relative }}
 *   formats to: 24 minutes ago
 *
 *   {{ aDate | moment:calendar }}
 *   formats to: 1:35 am Today
 *
 *   {{ aDate | moment:inverse-calendar }}
 *   formats to: Today at 1:35 am
*/
@Pipe({name: 'moment', pure: true})
export class MomentPipe implements PipeTransform {
  transform(value: Date, type: string = 'calendar'): string {
    if (value == null) {
      return '';
    }
    if (type === 'relative') {
      return moment(value).startOf('hour').fromNow();
    }
    if (type === 'inverse-calendar') {
      return moment(value).calendar(null, {
        lastDay: 'MMM D [at] h:mm a',
        sameDay: '[Today] [at] h:mm a',
        nextDay: 'MMM D [at] h:mm a',
        lastWeek: 'MMM D [at] h:mm a',
        nextWeek: 'MMM D [at] h:mm a',
        sameElse: 'MMM D [at] h:mm a'
      });
    }
    return moment(value).calendar(null, {
      lastDay: 'h:mm a MMM D',
      sameDay: 'h:mm a [Today]',
      nextDay: 'h:mm a MMM D',
      lastWeek: 'h:mm a MMM D',
      nextWeek: 'h:mm a MMM D',
      sameElse: 'h:mm a MMM D'
    });
  }
}
