import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {RestBase} from './rest-base';
import {LogService} from './log.service';
import {catchError, share} from 'rxjs/operators';


@Injectable()
export class AuthorizationRestService extends RestBase {

  API_AUTHORIZATION = '/authorization';
  API_PROFILE = '/profile';

  constructor(private logger: LogService, private http: HttpClient) {
    super();
  }

  isAuthorized(resourceName: string): Observable<boolean> {
    const link = this.buildPageLink(this.API_AUTHORIZATION + '?resource=' + resourceName);

    const headers = this.buildHeaders();

    return this.http.get<boolean>(link, {headers: headers});
  }

  getPermissions(): Observable<string[]> {
    const link = this.buildPageLink(this.API_PROFILE + '/permissions');

    const headers = this.buildHeaders();

    return this.http.get<string[]>(link, {headers: headers})
    .pipe(share(), catchError(AuthorizationRestService.handleError));

    // For testing
    /*return of(['dashboard_get', 'dashboard_update', 'users_get', 'dashboard_update_reviewer',
      'checklists_get', 'companies_get', 'products_get', 'iteration-requests_get',
      'sla-definitions_get', 'logs_get', 'doc-repo-config_get', 'results_get', 'files_get',
      'reviews_get', 'timeline_get', 'comments_update','qrt-instances_get']);*/

  }

  protected static handleError(error: HttpResponse<any> | any) {
    console.error(error);
    let message = (error.error && error.error.error) ? error.error.error : null;
    message = (!message && error.message) ? error.message : message;
    message = (!message && error.error && error.error.message) ? error.error.message : message;
    return throwError({
      status: error.status,
      message: message
    });
  }
}
