import {KeycloakEventType, KeycloakService} from 'keycloak-angular';

import {environment} from '../../environments/environment';
import {Config} from '../config/config';
import {HttpClient} from '@angular/common/http';
import {AuthGuardService} from '../signin/auth-guard.service';
import {MarkedOptions, MarkedRenderer} from 'ngx-markdown';


function getProfile(http: HttpClient) {
  const profileUrl = Config.getApiServer() + '/profile';
  http.get(profileUrl)
  .subscribe(info => {
    AuthGuardService.setUserInfo(info);
  }, err => {
    console.log('failed to get user profile');
    console.log(err);
    setTimeout(() => {
      getProfile(http);
    }, 3000);
  });
}


export function initializer(keycloak: KeycloakService, http: HttpClient): () => Promise<any> {
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        keycloak.keycloakEvents$.subscribe(data => {
          if (data.type == KeycloakEventType.OnAuthSuccess &&
            (AuthGuardService.getUserInfo() == null || Object.keys(AuthGuardService.getUserInfo()).length == 0)) {
            getProfile(http);
          }
        });
        keycloak.init({
          config: environment.keycloak,
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false
          },
          bearerExcludedUrls: []
        }).then(() => resolve())
        .catch((err) => {
          reject(err);
        });
      } catch (error) {
        reject(error);
      }
    });
  };
}

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.link = (href: string, title: string, text: string) => {
    return '<a href="' + href + '" title="' + (title || '') + '" target="_blank">' + text + '</a>';
  };

  return {
    renderer: renderer,
    gfm: true,
  };
}
