import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {HasPermissionService} from '../services/has-permission.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {
  private permissions: string | string[];
  private elseRef: any;
  private subscription: Subscription;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: HasPermissionService,
  ) {

  }

  ngOnInit(){
    this.subscription = this.permissionService.userPermissionsChanged$.subscribe(() => {
      this.updateView();
    })
  }

  @Input()
  set hasPermission(val: string | string[]) {
    this.permissions = val;
    this.updateView();
  }

  @Input()
  set hasPermissionElse(val) {
    this.elseRef = val;
    this.updateView();
  }

  private updateView() {
    this.viewContainer.clear();

    if (this.checkPermission()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (this.elseRef != null) {
      this.viewContainer.createEmbeddedView(this.elseRef);
    }
  }

  private checkPermission() {
    return this.permissionService.hasPermission(this.permissions);
  }

  ngOnDestroy() {
    if (this.subscription != null) {
      this.subscription.unsubscribe();
    }
  }
}
