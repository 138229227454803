import {Component, ElementRef, Input, OnChanges, SimpleChanges} from '@angular/core';
import {QCChecklist} from '../../../entities/qcChecklist';
import {TreeNode} from 'primeng/api';
import {ChecklistListSection} from '../../../entities/checklistListSection';
import {QCChecklistRestService} from '../../../core/services/checklist-rest.services';

@Component({
  selector: 'app-checklist-info',
  templateUrl: './checklist-info.component.html',
  styleUrls: ['./checklist-info.component.css']
})

export class ChecklistInfoComponent implements OnChanges {

  constructor(private element: ElementRef,
              private qcChecklistRestService: QCChecklistRestService) {
  }

  /**
   * The checklist to display
   * @type {QCChecklist}
   */
  @Input() checklist: QCChecklist = null;
  /**
   * The checklist id to display.
   * A request will be made to obtain the checklist data.
   * @default null
   * @type {number}
   */
  @Input('checklistId') checklistId: number = null;

  nodeSections: TreeNode[] = [];


  ngOnInit() {

  }

  // Lifecycle hook. angular.io for more info
  ngOnChanges(changes: SimpleChanges) {
    // set placeholder default value when no input given to pH property
    if (changes['checklistId']) {
      this.checklist = null;
      const value = changes['checklistId'].currentValue;
      if (value != null) {
        this.qcChecklistRestService.getById(value).subscribe(data => {
          this.checklist = data;
          this.readSections();
        });
      }
    } else if (changes['checklist']) {
      const value = changes['checklist'].currentValue;
      this.checklistId = value != null ? value.id : null;
      if (this.checklist.sections != null) {
        this.nodeSections = this.toTreeNode(this.checklist.sections);
        this.readSections();
      }
    }
  }

  readSections() {
    if (this.checklist != null && this.checklist.sections != null) {
      this.nodeSections = this.toTreeNode(this.checklist.sections);
    } else {
      this.nodeSections = [];
    }
  }

  toTreeNode(sections: ChecklistListSection[]): TreeNode[] {
    const treeNodes: TreeNode[] = [];
    sections.forEach((item) => {
      if (item.section == null) {
        return; // We skip the records which don't have a section
      }
      const node: TreeNode = {
        label: item.section.name,
        data: item,
        expanded: false,
        type: 'section'
      };
      if (item.section.itemSections != null) {
        const children = [];
        item.section.itemSections.forEach(citem => {
          if (!citem) {
            return;
          }
          children.push({
            label: citem.item != null ? citem.item.description : citem.group.description,
            data: citem,
            expanded: false,
            type: 'item',
          });
        });
        node.children = children;
      }
      treeNodes.push(node);
    });
    return treeNodes;
  }
}
