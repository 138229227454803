import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LogPublishersService} from './services/log-publishers';
import {LogService} from './services/log.service';
import {LogRestService} from './services/log-rest.service';
import {ChecklistItemCommentsRestService} from './services/checklist-item-comments-rest.services';
import {ChecklistListSectionsRestService} from './services/checklist-list-sections-rest.services';
import {ChecklistItemSectionsRestService} from './services/checklist-item-sections-rest.services';
import {ChecklistItemsRestService} from './services/checklist-items-rest.services';
import {ChecklistSectionsRestService} from './services/checklist-sections-rest.services';
import {QCChecklistRestService} from './services/checklist-rest.services';
import {ProductsRestService} from './services/products-rest.service';
import {CompaniesRestService} from './services/companies-rest.service';
import {MessageManager} from './services/message-manager.service';
import {SlaDefinitionsRestService} from './services/sla-definitions-rest.service';
import {UsersRestService} from './services/users-rest.service';
import {IterationRequestsRestService} from './services/iteration-requests-rest.service';
import {DocumentRepositoryConfigurationRestService} from './services/document-repository-configuration-rest.service';
import {ReviewRestService} from './services/review-rest.services';
import {DashboardsRestService} from './services/dashboards-rest.service';
import {QRTInstanceRestService} from './services/qrtinstance-rest.service';
import {GDriveRestService} from './services/gdrive-rest.service';
import {WindowMessageService} from './services/window-message.service';
import {ErrorComponent} from './components/errors/error.component';
import {PanelModule} from 'primeng/panel';
import {TimelineRestService} from './services/timeline-rest.service';
import {ReportsRestService} from './services/reports-rest.service';
import {UnauthorizedComponent} from './components/errors/unauthorized/unauthorized.component';
import {CacheService} from './services/cache.service';
import {StatsRestService} from './services/stats-rest.service';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
  ],
  declarations: [
    ErrorComponent,
    UnauthorizedComponent,
  ],
  providers: [
    LogPublishersService,
    LogService,
    LogRestService,
    ChecklistItemsRestService,
    ChecklistItemCommentsRestService,
    ChecklistSectionsRestService,
    ChecklistListSectionsRestService,
    ChecklistItemSectionsRestService,
    QCChecklistRestService,
    CompaniesRestService,
    ProductsRestService,
    MessageManager,
    SlaDefinitionsRestService,
    IterationRequestsRestService,
    UsersRestService,
    DocumentRepositoryConfigurationRestService,
    ReviewRestService,
    DashboardsRestService,
    QRTInstanceRestService,
    GDriveRestService,
    WindowMessageService,
    TimelineRestService,
    ReportsRestService,
    CacheService,
    StatsRestService,
  ],
  exports: [
    ErrorComponent,
    UnauthorizedComponent,
  ]
})
export class CoreModule {
}
