import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-form-error-message',
  templateUrl: './form-error-message.component.html',
  // styleUrls: ['./check-item-answer.component.css']
})

export class FormErrorMessageComponent implements OnInit {

  requiredMessage = 'Required';
  minLengthMessage = 'At least {minLength} characters are needed';
  maxLengthMessage = 'At most {maxLength} characters are permitted';
  minMessage = 'At least {min}';
  maxMessage = 'At most {max}';
  otherMessage = null;

  constructor(private element: ElementRef) {
  }

  /**
   * Name of the input element.
   * It will also be used as the property sent to backend when saving changes (when `url` is provided)
   * @type {string}
   */
  @Input() name = '';
  /**
   * Current form control input. Helpful in validating and accessing form control
   * @default new instance
   * @type {FormControl}
   */
  @Input() control: FormControl = new FormControl();
  // errors for the form control will be stored in this array
  errors: Array<any> = [];

  ngOnInit() {
  }

  // Lifecycle hook. angular.io for more info
  ngAfterViewInit() {
    // set placeholder default value when no input given to pH property

    // RESET the custom input form control UI when the form control is RESET
    this.control.valueChanges.subscribe(
      () => {
        this.checkErrors();
      }
    );
    this.control.statusChanges.subscribe(
      () => {
        this.checkErrors();
      }
    );
  }

  checkErrors() {
    // check condition if the form control is RESET
    this.errors = [];
    this.otherMessage = null;
    // setting, resetting error messages into an array (to loop) and adding the validation messages to show below the field area
    for (const key in this.control.errors) {
      if (this.control.errors.hasOwnProperty(key)) {
        if (key === 'required') {
        } else if (key === 'minlength') {
          this.minLengthMessage = 'At least ' + this.control.errors[key].requiredLength + ' characters are needed';
        } else if (key === 'maxlength') {
          this.maxLengthMessage = 'At most ' + this.control.errors[key].requiredLength + ' characters are permitted';
        } else if (key === 'min') {
          this.minMessage = 'At least ' + this.control.errors[key].min;
        } else if (key === 'max') {
          this.maxMessage = 'At most ' + this.control.errors[key].max;
        } else {
          // Nothing for now
          const error = this.control.errors[key];
          this.otherMessage = error['message'] != null ? error['message'] : 'Input Error';
        }
      }
    }
    console.log('errors');
    console.log(this.control.errors);
    console.log(this.errors);
  }
}
