import {Component, EventEmitter, Output} from '@angular/core';
import {AuthGuardService} from './auth-guard.service';
import {Router} from '@angular/router';
import {Config} from '../config/config';

@Component({
  selector: 'signin',
  templateUrl: './signin.component.html',
})
export class SignInComponent {

  @Output()
  private logOut = new EventEmitter();

  constructor(private authService: AuthGuardService) {
  }

  isAuthenticated() {
    return this.authService.isAuthenticated();
  }

  doLogin() {
    this.authService.doLogin();
  }

  doLogout() {
    this.authService.doLogout(Config.getFrontEndServer() + '/');
    this.logOut.emit();
  }

  get userName() {
    return this.authService.getUserName();
  }
}
