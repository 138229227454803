import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class QRTInstance extends DefaultEntity {
  name: string;
  description: string;

  adminMail: string ;
  url: string ;
  status: string ;
  //invitedUsers: InvitedUser[];
}

export interface QRTInstancePageable extends Pageable {
  content: QRTInstance[];
}

