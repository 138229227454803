import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Router} from "@angular/router";
import {AuthGuardService} from '../../../signin/auth-guard.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {

  items: MenuItem[];


  ngOnInit() {
    this.createItems();
  }

  @Output()
  private logOut = new EventEmitter();

  constructor(private authService: AuthGuardService, private router: Router) {
    this.authService.profileLoaded$.subscribe(info => {
      this.createItems();
    });
  }

  get authenticated() {
    return this.authService.isAuthenticated();
  }

  doLogin() {
    this.authService.doLogin();
  }

  doLogout() {
    this.authService.doLogout();
  }

  get userName() {
    return this.authService.getUserName();
  }

  get avatar() {
    return AuthGuardService.getUserInfo() != null ? AuthGuardService.getUserInfo().avatarUrl : '';
  }

  get fullName() {
    return (AuthGuardService.getUserInfo() != null && Object.keys(AuthGuardService.getUserInfo()).length > 0)
      ? AuthGuardService.getUserInfo().name + ' ' + AuthGuardService.getUserInfo().lastName : '';
  }

  private createItems() {
    this.items = [
      // {label: 'New', icon: 'pi pi-fw pi-plus'},
      // {label: 'Open', icon: 'pi pi-fw pi-download'},
      {
        label: this.fullName,
        items: [{
          label: 'Logout', icon: 'fa fa-sign-out-alt', command: (event: any) => {
            this.doLogout();
          }
        }]
      }
    ];
  }
}
