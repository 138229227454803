import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {IterationRequest, IterationRequestPageable} from '../../entities/iterationRequest';
import {SortOrder} from '../../entities/sortOrder';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';

@Injectable()
export class IterationRequestsRestService extends AuthorizedRestBaseService {

  API_ITERATION_REQUESTS = '/iterationrequests';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  getPage(pageNumber: number, pageSize: number, sort?: SortOrder[]): Observable<IterationRequestPageable> {

    const link = this.buildPageLink(this.API_ITERATION_REQUESTS, pageNumber, pageSize, this.sortToString(sort));
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<IterationRequestPageable>(link, {headers: opts})),
      catchError(IterationRequestsRestService.handleError));
  }

  deleteById(iterationRequestId: number) {
    const link = this.buildPageLink(this.API_ITERATION_REQUESTS + '/' + iterationRequestId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(IterationRequestsRestService.handleError));
  }

  getById(iterationRequestId: number): Observable<IterationRequest> {
    const link = this.buildPageLink(this.API_ITERATION_REQUESTS + '/' + iterationRequestId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<IterationRequest>(link, {headers: opts})),
      catchError(IterationRequestsRestService.handleError));
  }

  save(iterationRequest: IterationRequest): Observable<IterationRequest> {
    const link = this.buildPageLink(this.API_ITERATION_REQUESTS);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<IterationRequest>(link, iterationRequest, {headers: opts})),
      catchError(IterationRequestsRestService.handleError));
  }

  updateById(iterationRequestId: number, iterationRequest: IterationRequest): Observable<IterationRequest> {
    const link = this.buildPageLink(this.API_ITERATION_REQUESTS + '/' + iterationRequestId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<IterationRequest>(link, iterationRequest, {headers: opts})),
      catchError(IterationRequestsRestService.handleError));
  }
}
