import {Component, EventEmitter, Output} from '@angular/core';
import {ActivatedRoute, ActivationEnd, ActivationStart, Router} from '@angular/router';
import {MenuItem} from 'primeng/api';
import {AuthGuardService} from '../signin/auth-guard.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent {
  session: any;
  activeMenu: string;
  items: MenuItem[];
  @Output()
  private logOut = new EventEmitter();
  sidebarPinned: false;

  arLinks = ['dashboard', 'users', 'qrt-instances', 'checklists', 'checklistsections', 'checklistitems', 'products', 'companies', 'iteration-requests', 'sla-definitions', 'stats'];

  constructor(private authService: AuthGuardService, private router: Router, private route: ActivatedRoute) {
    this.router.events.pipe(filter(evt => evt instanceof ActivationEnd))
    .subscribe((data: ActivationStart) => {
      const path = data.snapshot.routeConfig.path;
      if (this.arLinks.includes(path)) {
        this.activeMenu = path;
      }
    });
  }

  doLogout() {
    this.authService.doLogout();
  }

  get authenticated() {
    return this.authService.isAuthenticated();
  }

}
