// https://www.codemag.com/article/1711021/Logging-in-Angular-Applications

import {Injectable} from '@angular/core';
import {LogPublisher, LogPublishersService} from './log-publishers';
import {LogEntry, LogLevel} from '../../entities/logEntry';

@Injectable()
export class LogService {
  level: LogLevel = LogLevel.All;
  logWithDate = true;
  publishers: LogPublisher[];

  constructor(private publishersService: LogPublishersService) {
    this.publishers = this.publishersService.publishers;
  }

  debug(msg: string, params?: any[]) {
    this.writeToLog(msg, LogLevel.Debug, params);
  }

  info(msg: string, params?: any[]) {
    this.writeToLog(msg, LogLevel.Info, params);
  }

  warn(msg: string, params?: any[]) {
    this.writeToLog(msg, LogLevel.Warn, params);
  }

  error(msg: string, params?: any[]) {
    this.writeToLog(msg, LogLevel.Error, params);
  }

  fatal(msg: string, params?: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, params);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret = false;

    if ((level >= this.level
          && level !== LogLevel.Off)
      || this.level === LogLevel.All) {
      ret = true;
    }
    return ret;
  }


  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (!this.shouldLog(level)) {
      return;
    }

    const entry: LogEntry = new LogEntry();
    entry.message = msg;
    entry.level = level;
    entry.extraInfo = params;
    entry.logWithDate = this.logWithDate;

    for (const logger of this.publishers) {
      logger.log(entry)
        .subscribe(response => true);
    }
  }

}

