import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {catchError, concatMap} from 'rxjs/operators';
import {SortOrder} from '../../entities/sortOrder';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Dashboard, DashboardPageable} from '../../entities/dashboard';
import {IterationRequestResult, IterationRequestStatus} from '../../entities/iterationRequest';


@Injectable()
export class DashboardsRestService extends AuthorizedRestBaseService {

  defaultRequestOrder = [new SortOrder('priority', 'asc'), new SortOrder('id', 'asc')];

  API_DASHBOARDS = '/dashboards';


  constructor(
    protected keycloakService: AuthGuardService,
    protected http: HttpClient
  ) {
    super(keycloakService, http);
  }

  getPage(pageNumber: number, pageSize: number, params: any): Observable<DashboardPageable> {

    const sort = this.sortToString(this.defaultRequestOrder);
    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_DASHBOARDS, pageNumber, pageSize, sort + '&' + strParams);

    return this.buildHeaders()
    .pipe(concatMap(opts => {
      return this.http.get<DashboardPageable>(link, {headers: opts});
    }));
  }


  getById(dashboardId: number): Observable<Dashboard> {
    const link = this.buildPageLink(this.API_DASHBOARDS + '/' + dashboardId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<Dashboard>(link, {headers: opts})));
  }


  createIteration(dashboardId: number): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/iteration');
    const body = {};

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<Dashboard>(link, body, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }


  assigneeRequestReviewer(dashboardId: number, reviewerEmail: string): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/request/reviewer');
    const body = {
      email: reviewerEmail
    };

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.put<Dashboard>(link, body, {headers: opts})));
  }

  assigneeReviewReviewer(dashboardId: number, reviewId: number, reviewerEmail: string): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/review/' + reviewId + '/reviewer');

    const body = {
      email: reviewerEmail
    };

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.put<Dashboard>(link, body, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }

  updateChecklist(dashboardId: number, qcChecklistId: number): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/request/checklist');
    const body = {
      id: qcChecklistId
    };

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.put<Dashboard>(link, body, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }

  addNewReview(dashboardId: number, reviewKind: string, reviewerEmail: string, previousReviewId: number): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/review');
    const body = {
      kind: reviewKind,
      reviewerEmail: reviewerEmail,
      previousReviewId: previousReviewId,
    };

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.put<Dashboard>(link, body, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }


  deleteReview(dashboardId: number, reviewId: number): Observable<Dashboard> {
    const link = this.buildPageLink(
      this.API_DASHBOARDS + '/' + dashboardId + '/review/' + reviewId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete<Dashboard>(link, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }


  makeStatusTransition(dashboardId: number, status: IterationRequestStatus, result?: IterationRequestResult): Observable<Dashboard> {
    const link = this.buildPageLink(this.API_DASHBOARDS + '/' + dashboardId + '/request/status');

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<Dashboard>(link, {
        status: status,
        result: result
      }, {headers: opts})),
      catchError(DashboardsRestService.handleError));
  }

  importJira(pageNumber: number, pageSize: number, params: any): Observable<DashboardPageable> {

    const sort = this.sortToString(this.defaultRequestOrder);
    let strParams = '';
    for (const p in params) {
      strParams += p + '=' + params[p] + '&';
    }
    const link = this.buildPageLink(this.API_DASHBOARDS + '/jira', pageNumber, pageSize, sort + '&' + strParams);

    return this.buildHeaders()
      .pipe(concatMap(opts => {
        return this.http.post<DashboardPageable>(link, {headers: opts});
      }));
  }

}
