import {Injectable} from '@angular/core';
import {Message, MessageService} from 'primeng/api';


@Injectable()
export class MessageManager {


  constructor (private messageService: MessageService) {

  }

  public addSuccess(summary: string, detail?: string) {
    this.messageService.add({summary: summary, severity: 'success', detail: detail});
  }

  public addInfo(summary: string, detail?: string) {
    this.messageService.add({summary: summary, severity: 'info', detail: detail});
  }

  public addWarn(summary: string, detail?: string) {
    this.messageService.add({summary: summary, severity: 'warn', detail: detail});
  }

  public addError(summary: string, detail?: string) {
    this.messageService.add({summary: summary, severity: 'error', detail: detail, sticky: true});
  }

  public add(message: Message) {
    this.messageService.add(message);
  }
}
