import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ReactiveFormsModule} from '@angular/forms';

import {AppComponent} from './app.component';

import {PanelModule} from 'primeng/panel';
import {TooltipModule} from 'primeng/tooltip';
import {ToastModule} from 'primeng/toast';
import {ConfirmationService, MessageService} from 'primeng/api';
import {ButtonModule} from 'primeng/button';

import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRoutingModule} from './app-routing.module';
import {MessageModule} from 'primeng/primeng';
import {SignInComponent} from './signin/signin.component';
import {SignedInComponent} from './signin/signedin.component';
import {LoginComponent} from './signin/login.component';
import {AuthGuardService} from './signin/auth-guard.service';
import {MainComponent} from './layout/main.component';
import {HomeComponent} from './home/home.component';
import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {AddAuthorizationInterceptor} from './shared/auth.interceptor';
import {AppErrorHandler} from './shared/app-error-handler';
import {MarkdownModule, MarkedOptions} from 'ngx-markdown';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthorizationRestService} from './core/services/authorization-rest.service';
import {initializer, markedOptionsFactory} from './utils/app-init';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PanelModule,
    ButtonModule,
    TooltipModule,
    MessageModule,
    HttpClientModule,
    ToastModule,
    AppRoutingModule,

    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      }
    }),
    CoreModule,
    SharedModule,
    KeycloakAngularModule
  ],
  declarations: [
    AppComponent,
    MainComponent,
    SignInComponent,
    SignedInComponent,
    LoginComponent,
    HomeComponent,
  ],
  providers: [
    ConfirmationService,
    MessageService,
    // AuthService,
    AuthGuardService,
    AuthorizationRestService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddAuthorizationInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      multi: true,
      deps: [KeycloakService, HttpClient]
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler
    }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
