// These first 3 lines will be deprecated by the final release
import {Component} from '@angular/core';

@Component({
  selector: 'signedin',
  template: `This window will close automatically ...`
})
export class SignedInComponent {
  constructor() {
  }
}
