import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';
import {ChecklistItemSection} from './checklistItemSection';
import {RequestType} from './requestType';

export class ChecklistSection extends DefaultEntity {
  itemSections: ChecklistItemSection[];
  key: string;
  name: string;
  description: string;
  kind: RequestType;
}

export interface ChecklistSectionPageable extends Pageable {
  content: ChecklistSection[];
}
