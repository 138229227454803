import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LogService} from './log.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {ContentType} from './rest-base';
import {concatMap} from 'rxjs/operators';
import {Cacheable} from '../../utils/cacheable.decorator';


@Injectable()
export class ReportsRestService extends AuthorizedRestBaseService {

  API_REPORTS = '/reports';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getEmailReportContent(iterationRequestId: number): Observable<any> {
    const link = this.buildPageLink(this.API_REPORTS + '/request/' + iterationRequestId);

    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.get(link, {headers: opts, responseType: 'text'})));
  }

}
