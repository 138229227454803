import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Calendar} from 'primeng/primeng';
import {ContentType, RestBase} from '../../../core/services/rest-base';
import {HasPermissionService} from '../../services/has-permission.service';

const INLINE_EDIT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InPlaceEditComponent),
  multi: true
};

@Component({
  selector: 'app-in-place-edit',
  templateUrl: './in-place-edit.component.html',
  providers: [INLINE_EDIT_CONTROL_VALUE_ACCESSOR],
  styleUrls: ['./in-place-edit.component.css']
})

export class InPlaceEditComponent implements ControlValueAccessor, OnInit {

  // Control Value Accessors for ngModel
  get value(): any {
    return this._value;
  }

  set value(v: any) {
    if (v !== this._value) {
      this._value = v;
      // this.onChangeValueAccessor(v);
    }
  }

  constructor(private element: ElementRef, private _renderer: Renderer2, private http: HttpClient,
              private permissionService: HasPermissionService,
              private changeDetectorRef: ChangeDetectorRef) {
  }

  @ViewChild('inlineEditControl') inlineEditControl: ElementRef; // input DOM element
  /**
   * Name of the input element.
   * It will also be used as the property sent to backend when saving changes (when `url` is provided)
   * @type {string}
   */
  @Input() name = '';
  /**
   * The placeholder of the input element
   * @default ''
   * @type {string}
   */
  @Input() label = '';
  /**
   * The type of the input element (text, password, url, email, etc.)
   *
   * @default text
   * @type {string}
   */
  @Input() type = 'text';
  /**
   * The placeholder of the input element.
   * It will also be used as the value to display to the user when in non-edit mode if there's no actual value
   *
   * @default 'Enter a value'
   * @type {string}
   */
  @Input() placeholder = 'Enter a value';
  /**
   * If the input is required
   * @default false
   * @type {boolean}
   */
  @Input() required = false;
  /**
   * If the editing is disabled.
   * When `true`, the input does not transform to editable nor the edit button appears
   * @default false
   * @type {boolean}
   */
  @Input() disabled = false; // Is input disabled?
  @Input() pencilVisible = false;
  /**
   * When provided, a PATCH (application/merge-patch+json) request is made to this URL.
   * The JSON sent only has one key-value pair. The key is defined by the `name` property and the value is the value of this input.
   * E.g. If the `name` property is set to "description" and
   * the value introduced by the user is "Test message" then the JSON sent to backend would be:
   * {"description" : "Test message"}
   * The request is sent when the confirm button is pressed and there are no validation errors
   * @default null
   * @type string
   */
  @Input() url: string = null;

  /**
   * Options to display in the dropdown. Used only when `type` property is set to 'select'
   * @default null
   * @type any[]
   */
  @Input() options: any[] = null; // Options to display in the dropdown
  /**
   * Option label to display in the dropdown for each item
   * @default null
   * @type {string}
   */
  @Input() optionLabel = null;
  /**
   * When provided, the property defined by `optionValue` in the selected option
   * will be used as the key in the JSON object set in the PATCH request to `url`.
   * E.g. If the options are Checkitem objects, and `name` property is set to 'description' and this property is assigned the value `key`
   * then the JSON sent to backend would be:
   * {"description" : selectedCheckItem["key"]}
   * @default null
   * @type {string}
   */
  @Input() optionValue = null;
  /**
   * Whether filtering is enabled for dropdowns (type = select)
   * @default false
   * @type {boolean}
   */
  @Input() filter = false;
  /**
   * Whether the dropdown is editable (e.g. that the user can enter a value different than the `options` provided)
   * @default false
   * @type {boolean}
   */
  @Input() editable = false;
  /**
   * The dateFormat to use when editing a date when using the `calendar` type
   * @default 'dd/mm/yy'
   * @type {string}
   */
  @Input() dateFormat = 'dd/mm/yy';

  /**
   * The dateFormat to use when displaying a date when using the `calendar` type
   * @default the same as `dateFormat`
   * @type {string}
   */
  @Input() dateDisplayFormat: string = null;

  /**
   * The permission to check to enable editing for this control
   * @default null
   * @type string
   */
  @Input() permission: string = null;


  /**
   * Current form control input. Helpful in validating and accessing form control
   * @default new instance
   * @type {FormControl}
   */
  @Input() control: FormControl = new FormControl();
  // errors for the form control will be stored in this array
  errors: Array<any> = [];

  /**
   * Triggered before actual confirmation of the the model's value.
   * `event.preventDefault()` can be used to stop confirmation
   * @type {EventEmitter<InPlaceEditEvent>}
   */
  @Output() confirming = new EventEmitter<InPlaceEditEvent>();
  /**
   * Triggered after actual confirmation of the the model's value.
   * @type {EventEmitter<InPlaceEditEvent>}
   */
  @Output('confirm') confirmEvent = new EventEmitter<InPlaceEditEvent>();
  /**
   * Triggering after the user canceled the changes to the input
   * @type {EventEmitter<InPlaceEditEvent>}
   */
  @Output() cancel = new EventEmitter<InPlaceEditEvent>();

  private _value = ''; // Private variable for input value
  private preValue = ''; // The value before clicking to edit
  editing = false; // Is Component in edit mode?
  public onChangeValueAccessor: any = Function.prototype; // Trascend the onChange event
  public onTouchedValueAccessor: any = Function.prototype; // Trascend the onTouch event

  // Required for ControlValueAccessor interface
  writeValue(value: any) {
    this._value = value;
  }

  // Required forControlValueAccessor interface
  public registerOnChange(fn: (_: any) => {}): void {
    this.onChangeValueAccessor = fn;
  }

  // Required forControlValueAccessor interface
  public registerOnTouched(fn: () => {}): void {
    this.onTouchedValueAccessor = fn;
  }

  get valueDisplay() {
    /*    console.log(this.type);
        console.log(this.optionLabel);
        console.log(this.value);
    */
    if (this.type === 'select') {
      if (this.optionLabel !== '' && this.value && this.value[this.optionLabel]) {
        return this.value[this.optionLabel];
      }
    } else if (this.type === 'calendar') {
      if (this.value) {
        const calendar = new Calendar(this.element, this._renderer, this.changeDetectorRef);
        return calendar.formatDate(this.value, this.dateDisplayFormat || this.dateFormat);
      }
    }
    return this.value;
  }

  private getValueToSend() {
    if (this.type === 'select' && this.value instanceof Object) {
      if (this.optionValue && this.value) {
        return this.value[this.optionValue];
      }
    }
    if (this.type == 'select' && (this.value == null || this.value == '')) {
      return null;
    }
    return this.value;
  }

  public confirm(value?: any) {
    if (value) {
      this.value = value;
    }
    this.editing = false;
    this.onChangeValueAccessor(this.value);
  }

  // Do stuff when the input element loses focus
  /*onBlur($event: Event) {
    this.editing = false;
  }*/

  // event fired when input value is changed . later propagated up to the form control using the custom value accessor interface
  onChange(e: Event, value: any) {
    // set changed value
    this._value = value;
    // propagate value into form control using control value accessor interface
    this.onChangeValueAccessor(value);

    // reset errors
    this.errors = [];
    // setting, resetting error messages into an array (to loop) and adding the validation messages to show below the field area
    for (const key in this.control.errors) {
      if (this.control.errors.hasOwnProperty(key)) {
        if (key === 'required') {
          this.errors.push('This field is required');
        } else if (key === 'maxlength') {
          this.errors.push('Maximum ' + this.control.errors[key].requiredLength + ' characters');
        } else {
          this.errors.push(this.control.errors[key]);
        }
      }
    }
    console.log('errors');
    console.log(this.control.errors);
    console.log(this.errors);
  }

  cancelChanges() {
    this.editing = false;
    if (this.preValue !== this.value) {
      this._value = this.preValue;
      this.onChangeValueAccessor(this.preValue);
    }
    this.control.reset(this._value, {emitEvent: false}); // The event was already emitted (if needed)
    const event = new InPlaceEditEvent(this.preValue, this.value);
    this.cancel.emit(event);
  }

  saveChanges() {
    if (this.errors.length > 0) {
      console.log('errors were found');
      return;
    }
    const beforeEvent = new InPlaceEditEvent(this.preValue, this.value);
    // Emit a "before" event
    this.confirming.emit(beforeEvent);
    if (!beforeEvent.isDefaultPrevented()) {
      if (this.url) {
        this.disabled = true;
        this.sendRequest().subscribe(data => {
          console.log(data);
          this.disabled = false;
          this.doConfirm();
        });
      } else {
        this.doConfirm();
      }
    }
  }

  private doConfirm() {
    this.editing = false;
    this.onChangeValueAccessor(this.value);
    this.control.reset(this._value, {emitEvent: false}); // The event was already emitted (if needed)

    const afterEvent = new InPlaceEditEvent(this.preValue, this.value);
    this.confirmEvent.emit(afterEvent);
  }

  // Start the editing process for the input element
  edit(value) {
    if (this.disabled || !this.checkPermission()) {
      return;
    }

    this.preValue = value;
    this.editing = true;

    // Focus on the input element just as the editing begins
    setTimeout(() => {
      if (this.inlineEditControl.nativeElement) {
        this.inlineEditControl.nativeElement.focus();
      }
    });
    // this._renderer.invokeElementMethod(this.inlineEditControl,
    //  'focus', []));
  }

  private sendRequest(): Observable<object> {
    const restBase = new RestBase();
    const link = restBase.buildPageLink(this.url);
    const headers = restBase.buildHeaders(ContentType.MERGE);
    console.log('property to send:' + this.name);
    console.log('value to send:' + this.getValueToSend());
    const object = {};
    object[this.name] = this.getValueToSend();
    return this.http.patch<object>(link, object, {headers: headers});
  }

  ngOnInit() {
  }

  // Lifecycle hook. angular.io for more info
  ngAfterViewInit() {
    // set placeholder default value when no input given to pH property

    // RESET the custom input form control UI when the form control is RESET
    this.control.valueChanges.subscribe(
      () => {
        // check condition if the form control is RESET
        if (this.control.value === '' || this.control.value == null || this.control.value === undefined) {
          this._value = '';
          this.element.nativeElement.value = '';
        }
      }
    );
  }

  private checkPermission(){
    if(this.permission != null) {
      return this.permissionService.hasPermission(this.permission);
    }
    return true; // If no permission is set, then we say it does have permission
  }
}

export class InPlaceEditEvent {
  private defaultPrevented = false;

  constructor(public oldValue: string, public newValue: string) {
  }

  public preventDefault() {
    this.defaultPrevented = true;
  }

  public isDefaultPrevented() {
    return this.defaultPrevented;
  }
}
