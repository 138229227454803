import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IterationRequest} from '../../entities/iterationRequest';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';

@Injectable()
export class GDriveRestService extends AuthorizedRestBaseService {

  private API_GDRIVE = '/google/drive';


  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  public generateSnapshot(dashboardId: number): Observable<IterationRequest> {
    const link = this.buildLink(this.API_GDRIVE + '/' + 'iterationrequest');

    const body = {
      action: 'CREATE_SNAPSHOT',
      entity: dashboardId
    };

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<IterationRequest>(link, body, {headers: opts})),
      catchError(GDriveRestService.handleError));

  }

}
