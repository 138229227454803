import {LogLevel} from '../../entities/logEntry';
import {Observable, throwError} from 'rxjs';
import { of } from 'rxjs';
import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {LogEntry} from '../../entities/logEntry';
import {Config} from '../../config/config';

@Injectable()
export class LogPublishersService {
  constructor(private http: HttpClient) {
    this.buildPublishers();
  }

  publishers: LogPublisher[] = [];

  buildPublishers(): void {
    this.publishers.push(new LogConsole());
    this.publishers.push(new LogLocalStorage());
    // this.publishers.push(new LogWebApi(this.http));
  }
}


export abstract class LogPublisher {
  location: string;

  abstract log(entry: LogEntry): Observable<boolean>;
  abstract clear(): Observable<boolean>;
}

export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    console.log(this.buildLogString(entry));
    return of(true);
  }

  clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }

  buildLogString(entry: LogEntry): string {
    let ret = '';

    if (entry.logWithDate) {
      ret = new Date() + ' - ';
    }
    ret += 'Type: ' + LogLevel[entry.level];
    ret += ' - Message: ' + entry.message;
    if (entry.extraInfo && entry.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(entry.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');
    // Is there at least one object in the array?
    if (params.some(p => typeof p === 'object')) {
      ret = '';
      // Build comma-delimited string
      for (const item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }
    return ret;
  }
}

export class LogLocalStorage extends LogPublisher {
  constructor() {
    super();

    this.location = 'logging';
  }

  log(entry: LogEntry): Observable<boolean> {
    let ret = false;
    let values: LogEntry[];

    try {
      // get previous values from local storage
      values = JSON.parse(localStorage.getItem(this.location)) || [];
      // add new log entry to array
      values.push(entry);
      // store array into local storage
      localStorage.setItem(this.location, JSON.stringify(values));

      ret = true;
    } catch (e) {
      console.log(e);
      // as something is wrong, clear local cache
      this.clear();
    }
    return of(ret);
  }

  clear(): Observable<boolean> {
    localStorage.removeItem(this.location);

    return of(true);
  }
}

export class LogWebApi extends LogPublisher {

  constructor(private http: HttpClient) {
    super();

    this.location = Config.getApiServer() +  '/api/logs';
  }

  log(entry: LogEntry): Observable<boolean> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    const options = {
      headers: headers
    };
    // Workaround - Use spread operator to avoid a compilation error
    return this.http.post(this.location, entry, {...options, observe: 'response'})
      .pipe(map(response => response.status === 201))
      .pipe(catchError(this.handleErrors));
  }

  clear(): Observable<boolean> {
    return of(true);
  }

  private handleErrors(error: any): Observable<any> {
    const errors: string[] = [];
    let msg = '';

    msg = 'Status: ' + error.status;
    msg += ' - Status Text: ' + error.statusText;
    console.log(error);
    if (error.error) {
      msg += ' - Exception Message: ' + error.error.message;
    }
    errors.push(msg);

    console.error('And error occurred when post log to REST API', errors);

    return throwError(errors);
  }

}
