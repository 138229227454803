import {Injectable} from '@angular/core';
import {WindowMessage} from '../../entities/windowMessage';
import {Config} from '../../config/config';


@Injectable()
export class WindowMessageService {

  destinationDomain = Config.getFrontEndServer();

  constructor() {

  }

  public sendMessage(destination: any, message: WindowMessage) {
    if (destination.contentWindow) {
      destination.contentWindow.postMessage(message, this.destinationDomain);
    } else if (destination.window) {
      destination.window.postMessage(message, this.destinationDomain);
    } else {
      destination.postMessage(message, this.destinationDomain);
    }
  }

}
