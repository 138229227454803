import {DefaultEntity} from './defaultEntity';
import {Pageable} from './pageble';

export class SlaDefinition extends DefaultEntity {
  description: string;
  durationInMinutes: number;
  name: string;
}

export interface SlaDefinitionPageable extends Pageable {
  content: SlaDefinition[];
}

