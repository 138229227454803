import {ReplaySubject} from 'rxjs/ReplaySubject';
import {CacheStoreBuilder} from '../core/services/cache.service';

// Idea taken from ngrx-cache and ngx-cacheable

export interface CacheConfig {
  cacheError?: boolean;
  ttl?: number;
}

export interface CacheRemoverConfig {
  startWith?: string;
}

export function Cacheable(cacheConfig: CacheConfig = {}) {
  const cacheError = !!cacheConfig.cacheError;

  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const oldFn = descriptor.value;
    const store = CacheStoreBuilder.getCache(target.constructor.name);
    descriptor.value = function (...args) {
      const k = propertyKey + '_' + args.join('_');
      const cached = store.get(k);
      if (!!cached) {
        console.log('key:' + k + ' from cache');
        return cached;
      }

      const sbj = new ReplaySubject();
      const ob = oldFn.bind(this)(...args);
      ob.subscribe(sbj);
      if (!cacheError) {
        sbj.subscribe(null, () => {
          store.remove(k);
        });
      }
      store.set(k, sbj, cacheConfig.ttl);
      return sbj;
    };
  };
}

export function CacheRemover(cacheRemoverConfig: CacheRemoverConfig = {}) {
  const startWith = cacheRemoverConfig.startWith;

  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    const oldFn = descriptor.value;
    const store = CacheStoreBuilder.getCache(target.constructor.name);
    descriptor.value = function (...args) {
      const sbj = new ReplaySubject();
      const ob = oldFn.bind(this)(...args);
      ob.subscribe(sbj);
      sbj.subscribe(() => {
        console.log('removing from cache: ' + target.constructor.name);
        if (startWith) { // Only remove the keys that start with some string
          store.keys().filter(s => s.startsWith(startWith)).forEach(s => store.remove(s));
        } else { // We remove all
          store.clear();
        }
      });
      return sbj;
    };
  };
}
