import {Config} from '../../config/config';
import {HttpHeaders} from '@angular/common/http';
import {SortOrder} from '../../entities/sortOrder';


export class RestBase {

  buildLink(append: string): string {
    let link = Config.getApiServer();
    if (append) {
      link += append;
    }
    return link;
  }

  buildPageLink(append: string, pageNumber?: number, pageSize?: number, additionalParams?: string) {
    let link = this.buildLink(append);

    if (pageNumber || pageSize || additionalParams) {
      link += '?';
      if (pageNumber) {
        link += 'pageNumber=' + pageNumber + '&';
      }
      if (pageSize) {
        link += 'pageSize=' + pageSize + '&';
      }
      if (additionalParams) {
        link += additionalParams;
      }
    }
    return link;
  }

  buildHeaders(contentType = ContentType.JSON): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': contentType
    });

    return headers;
  }

  sortToString(sortOrders?: SortOrder[]): string {
    if (!sortOrders) {
      return null;
    }
    let sort = '';
    sortOrders.forEach(function(s) {
      sort += s.getAsString() + '&';
    });
    return sort;
  }
}

export enum ContentType {
  JSON = 'application/json',
  MERGE = 'application/merge-patch+json',
  TEXT = 'text/plain',
  HTML = 'text/html'
}
