import {DefaultEntity} from './defaultEntity';
import {Company} from './company';
import {Product} from './product';
import {Pageable} from './pageble';
import {ChecklistListSection} from './checklistListSection';

export enum QCChecklistStatus {
  WaitingForApproval = 'WAITING_FOR_APPROVAL',
  InProgress = 'IN_PROGRESS',
  Approved = 'APPROVED',
  Active = 'ACTIVE',
  Retired = 'RETIRED'
}

export enum QCChecklistKind {
  General = 'GENERAL',
  Company = 'COMPANY',
  Product = 'PRODUCT'
}

export class QCChecklist extends DefaultEntity {
  company: Company;
  product: Product;
  status: QCChecklistStatus;
  kind: QCChecklistKind;
  name: string;
  description: string;
  key: string;
  release: string;
  sections: ChecklistListSection[];
  previousQCChecklist: QCChecklist;
  validStart: Date;
  validEnd: Date;
  gracePeriodDays: number;
  templateUrl: string;
}

export interface QCChecklistPageable extends Pageable {
  content: QCChecklist[];
}

