import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {ContentType} from './rest-base';
import {ChecklistItemComment, ChecklistItemCommentPageable} from '../../entities/checklistItemComment';
import {ChecklistItemReply} from '../../entities/checklistItemReply';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';

@Injectable()
export class ChecklistItemCommentsRestService extends AuthorizedRestBaseService {

  API_CHECKLIST_ITEM_COMMENTS = '/checklistitemcomments';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  getChecklistItemComments(pageNumber: number, pageSize: number, name?: string): Observable<ChecklistItemCommentPageable> {

    let link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS, pageNumber, pageSize);

    if (name) {
      link += (link.indexOf('?') > 0 ? '&' : '?') + 'name=' + name;
    }
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ChecklistItemCommentPageable>(link, {headers: opts}).pipe(map(res => {
      res.content = res.content.map(item => Object.assign(new ChecklistItemComment(), item as ChecklistItemComment));
      return res;
    }))));
  }

  deleteById(checklistItemCommentId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  getById(checklistItemCommentId: number): Observable<ChecklistItemComment> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ChecklistItemComment>(link, {headers: opts})));
  }

  getByReviewId(reviewId: number): Observable<ChecklistItemComment[]> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/?reviewId=' + reviewId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ChecklistItemComment[]>(link, {headers: opts}).pipe(map(res => {
        return res.map(c => Object.assign(new ChecklistItemComment(), c as ChecklistItemComment));
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  getByIterationId(iterationId: number, includePreviousIterations: boolean = false): Observable<ChecklistItemComment[]> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/?iterationId=' + iterationId
      + '&includePrevious=' + includePreviousIterations);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<ChecklistItemComment[]>(link, {headers: opts}).pipe(map(res => {
        return res.map(c => Object.assign(new ChecklistItemComment(), c as ChecklistItemComment));
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  save(checklistItemComment: ChecklistItemComment): Observable<ChecklistItemComment> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<ChecklistItemComment>(link, checklistItemComment, {headers: opts}).pipe(map(res => {
        return Object.assign(new ChecklistItemComment(), res as ChecklistItemComment);
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  updateById(checklistItemCommentId: number, item: ChecklistItemComment): Observable<ChecklistItemComment> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<ChecklistItemComment>(link, item, {headers: opts}).pipe(map(res => {
        return Object.assign(new ChecklistItemComment(), res as ChecklistItemComment);
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  saveReply(checklistItemCommentId: number, checklistItemReply: ChecklistItemReply): Observable<ChecklistItemReply> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId + '/replies');
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<ChecklistItemReply>(link, checklistItemReply, {headers: opts}).pipe(map(res => {
        return Object.assign(new ChecklistItemReply(), res as ChecklistItemReply);
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  updateReplyById(checklistItemCommentId: number, checklistItemReplyId: number, checklistItemReply: ChecklistItemReply): Observable<ChecklistItemReply> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId + '/replies/' + checklistItemReplyId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<ChecklistItemReply>(link, checklistItemReply, {headers: opts}).pipe(map(res => {
        return Object.assign(new ChecklistItemReply(), res as ChecklistItemReply);
      }))),
      catchError(ChecklistItemCommentsRestService.handleError));
  }

  deleteReplyById(checklistItemCommentId: number, checklistItemReplyId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_COMMENTS + '/' + checklistItemCommentId + '/replies/' + checklistItemReplyId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(ChecklistItemCommentsRestService.handleError));
  }


}
