import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {ChecklistItemSection} from '../../entities/checklistItemSection';
import {catchError, concatMap, map} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {ChecklistGroup} from '../../entities/checklistGroup';
import {ChecklistItem} from '../../entities/checklistItem';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {AuthGuardService} from "../../signin/auth-guard.service";

@Injectable()
export class ChecklistItemSectionsRestService extends AuthorizedRestBaseService {

  API_CHECKLIST_ITEM_SECTIONS = '/checklistitemsection';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  deleteById(checklistItemId: number) {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS + '/' + checklistItemId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

  save(checklistItemSection: ChecklistItemSection): Observable<ChecklistItemSection> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<ChecklistItemSection>(link, checklistItemSection, {headers: opts})),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

  updateById(checklistItemId: number, item: ChecklistItemSection): Observable<Object> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS + '/' + checklistItemId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<ChecklistItemSection>(link, item, {headers: opts})),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

  updateSectionOrder(checklistItemSectionId: number, order: number): Observable<void> {
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS + '/' + checklistItemSectionId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<void>(link, {order: order}, {headers: opts})),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

  findItemsAndGroups(name: string, pageNumber: number = 0, pageSize: number = 100): Observable<any> {
    let link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS, pageNumber, pageSize);

    link += (link.indexOf('?') > 0 ? '&' : '?') + 'name=' + name;
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<any>(link, {headers: opts}).pipe(map(res => {
        res.items.content = res.items.content.map(item => Object.assign(new ChecklistItem(), item as ChecklistItem));
        res.groups.content = res.groups.content.map(item => Object.assign(new ChecklistGroup(), item as ChecklistGroup));

        return res;
      }))),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

  saveGroupRelationWithName(name: string, checklistSectionId: number): Observable<ChecklistItemSection> {
    /*const checklistItemSection = new ChecklistItemSection();
    checklistItemSection.section = new ChecklistSection();
    checklistItemSection.section.id = checklistSectionId;
    checklistItemSection.section.key = 'SEC' + checklistSectionId;
    checklistItemSection.section.name = 'SEC Name ' + checklistSectionId;

    checklistItemSection.id = Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
    checklistItemSection.relationshipType = RelationshipType.Group;
    checklistItemSection.sectionOrder = Math.floor(Math.random() * (50 - 10 + 1)) + 10;

    checklistItemSection.group = group;
    group.id = Math.floor(Math.random() * (1000 - 200 + 1)) + 200;
    group.key = 'ABCDEF';
    return from([checklistItemSection]);*/
    const link = this.buildPageLink(this.API_CHECKLIST_ITEM_SECTIONS + '/' + checklistSectionId + '/group');
    const body = {'name': name};
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<ChecklistItemSection>(link, body, {headers: opts})),
      catchError(ChecklistItemSectionsRestService.handleError));
  }

}
