

export class SortOrder {

  name: string;
  order = 'asc';


  constructor(name?: string, order?: string) {
    this.name = name;
    if (order) {
      this.order = order;
    }
  }

  getAsString(): string {
    return this.name + ':' + this.order;
  }

}
