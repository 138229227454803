import {Pipe, PipeTransform} from '@angular/core';
import {isArray} from 'util';


/**
 * Allows to sort entities by given field.
 *
 * Example calls:
 *
 * <li> <code>"*ngFor="let review of dashboard.reviews | sortEntityBy:'id:asc'""</code>
 * <li> <code>"list | sortEntityBy:'id:asc'"</code>
 * <li> <code>"list | sortEntityBy:'creationDate:dec'"</code>
 */
@Pipe({
  name: 'sortEntityBy'
})
export class SortEntityByPipe implements PipeTransform {


  transform(value: any, sortDefinition: string): any {
    if (!isArray(value)) {
      console.log('Input is not an array');
      return value;
    }

    let sortOrder: SortOrder;
    try {
      sortOrder = this.splitSortDefinition(sortDefinition);
    } catch (e) {
      console.log(e);
      return value;
    }

    const entities = value as Array<any>;

    entities.sort(function(e1, e2) {
      if (!e1[sortOrder.field] && !e2[sortOrder.field]) {
        return 0;
      }
      if (!e1[sortOrder.field] && e2[sortOrder.field]) {
        return -sortOrder.order;
      }
      if (e1[sortOrder.field] && !e2[sortOrder.field]) {
        return sortOrder.order;
      }

      if (e1[sortOrder.field] < e2[sortOrder.field]) {
        return -sortOrder.order;
      }
      if (e1[sortOrder.field] > e2[sortOrder.field]) {
        return sortOrder.order;
      }
      return 0;
    });

    return entities;
  }


  private splitSortDefinition(sortDefinition: string): SortOrder {
    if (!sortDefinition) {
      throw new Error('Sort definition not provided');
    }
    const strings = sortDefinition.split(':', 2);

    const sort = new SortOrder();

    if (!strings[0]) {
      throw new Error('Can\'t find field to sort by');
    }
    sort.field = strings[0];

    if (strings[1] && strings[1] === 'desc') {
      sort.order = -1;
    }

    return sort;
  }
}


class SortOrder {
  field: string;
  order = 1;
}
