import {QCChecklist, QCChecklistPageable} from '../../entities/qcChecklist';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {LogService} from './log.service';

import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {catchError, concatMap} from 'rxjs/operators';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class QCChecklistRestService extends AuthorizedRestBaseService {

  API_CHECKLISTS = '/checklists';

  constructor(private logger: LogService, protected http: HttpClient, protected keycloakService: AuthGuardService) {
    super(keycloakService, http);
  }

  @Cacheable()
  getNonRetiredChecklists(): Observable<QCChecklist[]> {

    const link = this.buildPageLink(this.API_CHECKLISTS+ '/non-retired');

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<QCChecklist[]>(link, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

  @Cacheable()
  getChecklists(pageNumber: number, pageSize: number): Observable<QCChecklistPageable> {

    const link = this.buildPageLink(this.API_CHECKLISTS, pageNumber, pageSize);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<QCChecklistPageable>(link, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

  @CacheRemover()
  deleteById(checklistItemId: number) {
    const link = this.buildPageLink(this.API_CHECKLISTS + '/' + checklistItemId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

  getById(checklistItemId: number): Observable<QCChecklist> {
    const link = this.buildPageLink(this.API_CHECKLISTS + '/' + checklistItemId);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<QCChecklist>(link, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

  @CacheRemover()
  save(checklistItem: QCChecklist): Observable<QCChecklist> {
    const link = this.buildPageLink(this.API_CHECKLISTS);

    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<QCChecklist>(link, checklistItem, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

  @CacheRemover()
  updateById(checklistItemId: number, item: QCChecklist): Observable<Object> {
    const link = this.buildPageLink(this.API_CHECKLISTS + '/' + checklistItemId);

    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<QCChecklist>(link, item, {headers: opts})),
      catchError(QCChecklistRestService.handleError));
  }

}
