import {
  DocumentRepositoryConfiguration,
  DocumentRepositoryConfigurationPageable
} from '../../entities/documentrepositoryconfiguration';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from "./authorized-rest-base.service";
import {AuthGuardService} from "../../signin/auth-guard.service";
import {catchError, concatMap} from "rxjs/operators";
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class DocumentRepositoryConfigurationRestService extends AuthorizedRestBaseService {

  API_CONFIG = '/documentrepositoryconfigurations';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<DocumentRepositoryConfigurationPageable> {

    const link = this.buildPageLink(this.API_CONFIG, pageNumber, pageSize);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<DocumentRepositoryConfigurationPageable>(link, {headers: opts})),
      catchError(DocumentRepositoryConfigurationRestService.handleError));
  }

  @CacheRemover()
  deleteById(configurationId: number) {
    const link = this.buildPageLink(this.API_CONFIG + '/' + configurationId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(DocumentRepositoryConfigurationRestService.handleError));
  }

  @Cacheable()
  getById(configurationId: number): Observable<DocumentRepositoryConfiguration> {
    const link = this.buildPageLink(this.API_CONFIG + '/' + configurationId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<DocumentRepositoryConfiguration>(link, {headers: opts})),
      catchError(DocumentRepositoryConfigurationRestService.handleError));
  }

  @CacheRemover()
  save(configuration: DocumentRepositoryConfiguration): Observable<DocumentRepositoryConfiguration> {
    const link = this.buildPageLink(this.API_CONFIG);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<DocumentRepositoryConfiguration>(link, configuration, {headers: opts})),
      catchError(DocumentRepositoryConfigurationRestService.handleError));
  }

  @CacheRemover()
  updateById(configurationId: number, configuration: DocumentRepositoryConfiguration): Observable<DocumentRepositoryConfiguration> {
    const link = this.buildPageLink(this.API_CONFIG + '/' + configurationId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<DocumentRepositoryConfiguration>(link, configuration, {headers: opts})),
      catchError(DocumentRepositoryConfigurationRestService.handleError));
  }
}
