import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {forwardRef, Inject} from '@angular/core';
import {AuthGuardService} from "../signin/auth-guard.service";

export class AddAuthorizationInterceptor implements HttpInterceptor {

  constructor(@Inject(forwardRef(() => AuthGuardService)) private authService: AuthGuardService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request to add the new header
    // if (this.authService.isAuthenticated()) {
    //   const clonedRequest = req.clone({
    //     headers: req.headers.set('Authorization', 'Bearer ' + this.authService.getToken())
    //   });
    //
    //   // Pass the cloned request instead of the original request to the next handle
    //   return next.handle(clonedRequest);
    // }
    return next.handle(req);
  }
}
