import {DefaultEntity} from './defaultEntity';
import {Company} from './company';
import {Pageable} from './pageble';

export class Product extends DefaultEntity {
  company: Company;
  name: string;
  description: string;
}

export interface ProductPageable extends Pageable {
  content: Product[];
}
