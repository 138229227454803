import { Pageable } from './pageble';
import { DefaultEntity } from './defaultEntity';
import { Company } from './company';
import { DocumentRepositoryPermission } from './documentrepositorypermission';
export class DocumentRepositoryConfiguration extends DefaultEntity {

  type: string;
  name: string;
  description: string;
  snapshotFolderId: string;
  //permissions: DocumentRepositoryPermission[];
  permission: string;
  company: Company;
}

export interface DocumentRepositoryConfigurationPageable extends Pageable {
  content: DocumentRepositoryConfiguration[];
}
