import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {SlaDefinition, SlaDefinitionPageable} from '../../entities/slaDefinition';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class SlaDefinitionsRestService extends AuthorizedRestBaseService {

  API_SLA_DEFINITIONS = '/sladefinitions';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<SlaDefinitionPageable> {

    const link = this.buildPageLink(this.API_SLA_DEFINITIONS, pageNumber, pageSize);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<SlaDefinitionPageable>(link, {headers: opts})),
      catchError(SlaDefinitionsRestService.handleError));

  }

  @CacheRemover()
  deleteById(slaDefinitionId: number) {
    const link = this.buildPageLink(this.API_SLA_DEFINITIONS + '/' + slaDefinitionId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(SlaDefinitionsRestService.handleError));
  }

  @Cacheable()
  getById(slaDefinitionId: number): Observable<SlaDefinition> {
    const link = this.buildPageLink(this.API_SLA_DEFINITIONS + '/' + slaDefinitionId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<SlaDefinition>(link, {headers: opts})),
      catchError(SlaDefinitionsRestService.handleError));
  }

  @CacheRemover()
  save(slaDefinition: SlaDefinition): Observable<SlaDefinition> {
    const link = this.buildPageLink(this.API_SLA_DEFINITIONS);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<SlaDefinition>(link, slaDefinition, {headers: opts})),
      catchError(SlaDefinitionsRestService.handleError));
  }

  @CacheRemover()
  updateById(slaDefinitionId: number, slaDefinition: SlaDefinition): Observable<SlaDefinition> {
    const link = this.buildPageLink(this.API_SLA_DEFINITIONS + '/' + slaDefinitionId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<SlaDefinition>(link, slaDefinition, {headers: opts})),
      catchError(SlaDefinitionsRestService.handleError));
  }
}
