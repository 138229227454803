import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ContentType} from './rest-base';
import {Company, CompanyPageable} from '../../entities/company';
import {LogService} from './log.service';
import {AuthorizedRestBaseService} from './authorized-rest-base.service';
import {AuthGuardService} from '../../signin/auth-guard.service';
import {catchError, concatMap} from 'rxjs/operators';
import {Cacheable, CacheRemover} from '../../utils/cacheable.decorator';

@Injectable()
export class CompaniesRestService extends AuthorizedRestBaseService {

  API_COMPANIES = '/companies';

  constructor(private logger: LogService, protected keycloakService: AuthGuardService, protected http: HttpClient) {
    super(keycloakService, http);
  }

  @Cacheable()
  getPage(pageNumber: number, pageSize: number): Observable<CompanyPageable> {

    const link = this.buildPageLink(this.API_COMPANIES, pageNumber, pageSize);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<CompanyPageable>(link, {headers: opts})),
      catchError(CompaniesRestService.handleError));
  }

  @CacheRemover()
  deleteById(companyId: number) {
    const link = this.buildPageLink(this.API_COMPANIES + '/' + companyId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.delete(link, {headers: opts})),
      catchError(CompaniesRestService.handleError));
  }

  @Cacheable()
  getById(companyId: number): Observable<Company> {
    const link = this.buildPageLink(this.API_COMPANIES + '/' + companyId);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.get<Company>(link, {headers: opts})),
      catchError(CompaniesRestService.handleError));
  }

  @CacheRemover()
  save(company: Company): Observable<Company> {
    const link = this.buildPageLink(this.API_COMPANIES);
    return this.buildHeaders()
    .pipe(concatMap(opts => this.http.post<Company>(link, company, {headers: opts})),
      catchError(CompaniesRestService.handleError));
  }

  @CacheRemover()
  updateById(companyId: number, company: Company): Observable<Company> {
    const link = this.buildPageLink(this.API_COMPANIES + '/' + companyId);
    return this.buildHeaders(ContentType.MERGE)
    .pipe(concatMap(opts => this.http.patch<Company>(link, company, {headers: opts})),
      catchError(CompaniesRestService.handleError));
  }
}
